import { AuthenticationResult, Configuration, PopupRequest, PublicClientApplication, SsoSilentRequest } from "@azure/msal-browser";
import { AccountInfo, AuthError } from "@azure/msal-common";
import { WEB_APPLICATION_ID } from "../../constants";
import { ACTION_SIGNED_OUT } from "./constants";

export class MsalManager {
  private static _instance: MsalManager;

  private _msalInstance: PublicClientApplication | null = null;

  private async getMsalInstance(): Promise<PublicClientApplication> {
    if (!this._msalInstance) {
      const msalConfig = this.getClientConfig();

      this._msalInstance = new PublicClientApplication(msalConfig);
      await this._msalInstance.initialize();
    }

    return this._msalInstance;
  }

  private constructor() { }

  public async getAllAccounts(): Promise<AccountInfo[]> {
    const msalInstance = await this.getMsalInstance();
    return msalInstance.getAllAccounts();
  }

  public async acquireTokenSilent(scopes: string[] = ["api://" + window.location.host + "/" + WEB_APPLICATION_ID + "/access_as_user"]): Promise<AuthenticationResult> {
    const msalInstance = await this.getMsalInstance();
    return msalInstance.acquireTokenSilent({
      scopes: scopes,
    });
  }

  public async silentLogin(
    scopes: string[] = ["api://" + window.location.host + "/" + WEB_APPLICATION_ID + "/access_as_user"],
    loginHint?: string,
    account?: AccountInfo
  ): Promise<string> {

    const msalInstance = await this.getMsalInstance();

    const silentRequest: SsoSilentRequest = {
      scopes: scopes,
    };
    if (loginHint) {
      silentRequest.loginHint = loginHint;
    }
    if (account) {
      silentRequest.account = account;
    }

    const silentResponse = await msalInstance.ssoSilent(silentRequest);

    msalInstance.setActiveAccount(silentResponse.account);
    return silentResponse.accessToken;
  }

  public async login(
    scopes: string[] = ["api://" + window.location.host + "/" + WEB_APPLICATION_ID + "/access_as_user"],
    ...parameters: string[]
  ): Promise<string> {
    // eslint-disable-next-line no-undef
    if (Office.context.platform === Office.PlatformType.OfficeOnline) {
      return await this.getWebToken(scopes);
    } else {
      return await this.getDesktopToken(...parameters);
    }
  }

  private async getWebToken(scopes: string[]): Promise<string> {
    const isReLoginRequired = localStorage?.getItem(ACTION_SIGNED_OUT) === "true";

    const popupRequest: PopupRequest = {
      scopes: scopes,
      prompt: isReLoginRequired ? "login" : "select_account",
    };

    try {
      const msalInstance = await this.getMsalInstance();
      const authResult: AuthenticationResult = await msalInstance.acquireTokenPopup(popupRequest);

      // Configure MSAL to use the signed-in account as the active account for future requests.
      msalInstance.setActiveAccount(authResult.account);

      // const homeId = authResult.account.homeAccountId;
      // // Configure MSAL to use the signed-in account as the active account for future requests.
      // const homeAccount = msalInstance.getAccountByHomeId(homeId);
      // msalInstance.setActiveAccount(homeAccount);

      return authResult.accessToken;
    } catch (error) {
      if (error instanceof AuthError) {
        console.error("User interaction is needed to get a token");
      }
      console.error("Error logging in", error);
    }

    return "";
  }

  private async getDesktopToken(...params: string[]): Promise<string> {
    const msalInstance = await this.getMsalInstance();

    return new Promise((resolve, reject) => {
      let loginDialog: Office.Dialog = null;

      const processMessage = (args: { message: string, origin: string | undefined }) => {
        if (process.env.DEBUG_LOG) {
          console.debug("Message received in processMessage", args);
        }
        
        const response = JSON.parse(args.message);

        if (response.status === "success") {
          // We now have a valid access token.
          const homeId = response.accountId;
          const homeAccount = msalInstance.getAccountByHomeId(homeId);
          msalInstance.setActiveAccount(homeAccount);

          loginDialog.close();
          const accessToken = response.result;
          resolve(<string>accessToken);
        } else {
          // Something went wrong with authentication or the authorization of the web application.
          loginDialog.close();
          reject(response.error);
        }
      };

      const urlParams = params.join("&");
      const loginDialogURL = `https://${location.hostname}${
        location.port ? ":" + location.port : ""
      }/dialog.html?${urlParams}`;

      // eslint-disable-next-line no-undef
      Office.context.ui.displayDialogAsync(loginDialogURL, { height: 60, width: 30 }, function (result) {
        loginDialog = result.value;
        // eslint-disable-next-line no-undef
        loginDialog.addEventHandler(Office.EventType.DialogMessageReceived, processMessage);
      });
    });
  }

  private getClientConfig(): Configuration {
    const redirectUri =
      process.env.SERVER_ENVIRONMENT !== "pr"
        ? "https://" + window.location.host + "/index.html"
        : `https://${process.env.SERVER}/index.html`;

    return {
      auth: {
        clientId: WEB_APPLICATION_ID,
        authority: "https://login.microsoftonline.com/common",
        redirectUri: redirectUri,
      },
      cache: {
        cacheLocation: "localStorage", // localstorage gives SSO
      },
    };
  }

  static createInstance() {
    if (!MsalManager._instance) {
      MsalManager._instance = new MsalManager();
    }
    return MsalManager._instance;
  }
}

export const msalManager = MsalManager.createInstance();
