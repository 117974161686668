import { ToolbarOptions } from "@zebrabi/global-toolbar-old/interface/ToolbarOption";
import BaseSwitcherWithHeaderOld from "./BaseSwitcherWithHeaderOld";
import * as d3 from "../../d3";
import { P, DIV, DRAGSTART, PX, DRAGEND, CLICK, DRAG } from "../../library/constants";
import { getColumnRole } from "@zebrabi/data-helpers/visualHelpers";
import { getOfficeSettings } from "@zebrabi/office-settings";
import { DATA_SOURCE, DataSource, createDialog } from "@zebrabi/data-helpers/editData";
import { MeasureRoles } from "@zebrabi/data-helpers/fieldAssignment";
import { DataViewMetadataColumn } from "@zebrabi/table-data";
import { AUTO } from "@zebrabi/constants";
import { GlobalChartsState } from "../../chartsGlobalState";
import { ChartSettings } from "../../settings/chartSettings";

export default class FieldsChartsSwitcher extends BaseSwitcherWithHeaderOld {
    static readonly CLASS_NAME = `FieldsChartsSwitcher`;

    public bucketActual: d3.Selection<HTMLDivElement, any, any, any>;
    public bucketPY: d3.Selection<HTMLDivElement, any, any, any>;
    public bucketPL: d3.Selection<HTMLDivElement, any, any, any>;
    public bucketFC: d3.Selection<HTMLDivElement, any, any, any>;
    public bucketComment: d3.Selection<HTMLDivElement, any, any, any>;

    toolbarOptions: ToolbarOptions = {
        collapsed: true,
        elementName: "Data fields",
        icon: "fields-icon-base64",
        type: "button",
        actions: [],
    };

    constructor(private editDataSourceHandler: (settings: ChartSettings) => void) {
        super();
    }

    public getClassName(): string {
        return FieldsChartsSwitcher.CLASS_NAME;
    }

    buttonAction(action: string, message: string): void {
    }

    update(message: Map<string, any>): void { };

    createMenuItems(switcherMenuContainer: HTMLElement, active = "") {
        super.createMenuItems(switcherMenuContainer, active);
        const fieldsForm = d3.select(switcherMenuContainer.querySelector(".body")).append(DIV).classed("fields-settings-form-2", true);
        const innerForm = fieldsForm.append(DIV).classed("inner-form", true);
        this.createFieldsForm(innerForm);
        //this.observeFormChanges(fieldsForm.node());
    }

    private createFieldsForm(innerForm: d3.Selection<HTMLDivElement, any, any, any>) {
        innerForm.append(P).text("Actual");
        this.bucketActual = innerForm.append(DIV).classed("bucket actual", true);
        innerForm.append(P).text("Previous Year");
        this.bucketPY = innerForm.append(DIV).classed("bucket py", true);
        innerForm.append(P).text("Plan");
        this.bucketPL = innerForm.append(DIV).classed("bucket pl", true);
        innerForm.append(P).text("Forecast");
        this.bucketFC = innerForm.append(DIV).classed("bucket fc", true);

        innerForm.append(P).text("Comments");
        this.bucketComment = innerForm.append(DIV).classed("bucket comment", true);

        this.drawValueFieldsItems(innerForm);

        if (Office.context.host === Office.HostType.Excel) {
            this.createExcelEditDataSourceUI(innerForm);
        }
    }

    private drawValueFieldsItems(innerForm: d3.Selection<HTMLDivElement, any, any, any>) {
        const dataView = GlobalChartsState.dataView;

        if (!dataView?.metadata?.columns) {
            return;
        }

        dataView?.metadata?.columns?.forEach((dataColumn) => {
            const item = innerForm.append(DIV).classed("item-vf", true).text(dataColumn.displayName);

            const currentRole = <MeasureRoles>getColumnRole(dataColumn);

            const bucket = this.getBucketForColumnRole(dataColumn);
            if (!currentRole || !bucket) {
                return;
            }

            bucket.node().append(item.node());
            item.data([dataColumn]);

            if (dataColumn.index === undefined) {
                return;
            }

            const draggable = d3.drag()
                .on(DRAGSTART, () => {
                    const itemNode = item.node();
                    const topPosition = itemNode.parentElement.offsetTop;
                    item.style("top", topPosition + PX);
                    item.style("position", "absolute");

                    //item.style("pointer-events", "none");
                    innerForm.selectAll<HTMLDivElement, any>(".item-vf")
                        .style("pointer-events", "none");
                    item.style("z-index", 10);
                })
                .on(DRAG, (event: d3.D3DragEvent<any, any, any>) => {
                    const itemNode = item.node();
                    const topPosition = itemNode.parentElement.offsetTop + event.y;
                    item.style("top", topPosition + PX);
                }).on(DRAGEND, (event: d3.D3DragEvent<any, any, any>) => {
                    item.style("position", "relative");

                    //item.style("pointer-events", "auto");
                    innerForm.selectAll<HTMLDivElement, any>(".item-vf")
                        .style("pointer-events", AUTO);
                        
                    item.style("z-index", null);
                    item.style("top", null);

                    let newRole = this.findRoleUnderCursor(event);

                    if (!newRole) {
                        newRole = currentRole;
                    } else {
                        const newBucket = this.getBucketForRole(newRole);
                        newBucket.node().append(item.node());
                    }

                    const returnData = new Map<string, any>();

                    // Don't switch roles for "Values" role, allow 2 values in a bucket
                    if (this.shouldSwitchRoles(newRole, dataView.metadata.columns)) {
                        const existingColumn = dataView.metadata.columns.find(column => column.roles[newRole]);
                        if (existingColumn) {
                            this.setNewMeasureRole(currentRole, existingColumn.index, returnData);
                            existingColumn.roles = {
                                [currentRole]: true
                            };
                        }
                    }

                    this.setNewMeasureRole(newRole, dataColumn.index, returnData);

                    let columnToSwitch = dataView.metadata.columns.find(valsrc => valsrc.index === dataColumn.index);   // to-check: just set new role for the dataColumn itself?
                    columnToSwitch.roles = {
                        [newRole]: true
                    };

                    this.notify(returnData, `${this.getClassName()}Observer`);
                });

            item.call(draggable);
        });
    }

    private shouldSwitchRoles(newRole: MeasureRoles, columns: DataViewMetadataColumn[]): boolean {
        return newRole !== MeasureRoles.Values
            || newRole === MeasureRoles.Values && columns.filter(column => column.roles[MeasureRoles.Values]).length > 1;
    }

    private createExcelEditDataSourceUI(innerForm: d3.Selection<HTMLDivElement, any, any, any>) {
        const formParent = innerForm.node().parentElement;
        const editDataDiv = document.createElement("div");
        formParent.insertBefore(editDataDiv, innerForm.node());
        editDataDiv.classList.add("edit-source-data");
        const editDataDivSelection = d3.select(editDataDiv);

        const dataSource = getOfficeSettings(DATA_SOURCE);
        const dataSourceDisplayString = this.getDataSourceDisplayString(dataSource);

        editDataDivSelection.append(P).classed("data-source-label", true)
            .text("Data source");
        const editDataSourceDiv = editDataDivSelection.append(DIV);

        editDataSourceDiv.append(P).classed("data-source", true)
            .text(dataSourceDisplayString);

        if (dataSource) {
            const editDatBtn = editDataSourceDiv.append("button")
                .classed("edit-data-source-btn", true);

            const dataEditHandler = () => {
                this.editDataSourceHandler(GlobalChartsState.settings);
            };
            editDatBtn.on(CLICK, (event: MouseEvent) => {
                if (event?.detail > 1) {    // prevent double click
                    return;
                }
                createDialog(getOfficeSettings(DATA_SOURCE), dataEditHandler);
            });
        }
    }

    private getDataSourceDisplayString(dataSource: DataSource): string {
        if (!dataSource) {
            return "";
        }
        else if (dataSource.range) {
            return "Range: " + dataSource.range;
        }
        else if (dataSource.table) {
            return "Excel table: " + dataSource.table;
        }
        else if (dataSource.pivotTable) {
            return "Pivot table: " + dataSource.pivotTable;
        }
    }

    public setNewMeasureRole(role: MeasureRoles, index: number, returnData: Map<string, any>) {
        const settings = GlobalChartsState.settings;
        if (index < settings.measureRoles?.length) {
            settings.measureRoles[index] = role;
            returnData.set("measureRoles", settings.measureRoles);
        }
    }

    private getBucketForColumnRole(column: DataViewMetadataColumn) {
        if (column.roles[MeasureRoles.Values]) {
            return this.bucketActual;
        } else if (column.roles[MeasureRoles.PreviousYear]) {
            return this.bucketPY
        } else if (column.roles[MeasureRoles.Plan]) {
            return this.bucketPL;
        } else if (column.roles[MeasureRoles.Forecast]) {
            return this.bucketFC;
        } else if (column.roles[MeasureRoles.Comments]) {
            return this.bucketComment;
        } else {
            return null;
        }
    }

    private getBucketForRole(role: MeasureRoles): d3.Selection<HTMLDivElement, any, any, any> {
        switch (role) {
            case MeasureRoles.Values:
                return this.bucketActual;
            case MeasureRoles.PreviousYear:
                return this.bucketPY;
            case MeasureRoles.Plan:
                return this.bucketPL;
            case MeasureRoles.Forecast:
                return this.bucketFC;
            case MeasureRoles.Comments:
                return this.bucketComment;
            default:
                return null;
        }
    }

    private findRoleUnderCursor(d3Event): MeasureRoles {
        let foundRole = null;

        const target = d3Event?.sourceEvent?.target ?? document.elementFromPoint(d3Event?.sourceEvent?.clientX, d3Event?.sourceEvent?.clientY);

        // console.log(target);
        // console.log(d3Event, d3Event?.sourceEvent?.target);
        if (target) {
            switch (target) {
                case this.bucketActual.node():
                    foundRole = MeasureRoles.Values;
                    break;
                case this.bucketPY.node():
                    foundRole = MeasureRoles.PreviousYear;
                    break;
                case this.bucketPL.node():
                    foundRole = MeasureRoles.Plan;
                    break;
                case this.bucketFC.node():
                    foundRole = MeasureRoles.Forecast;
                    break;
                case this.bucketComment.node():
                    foundRole = MeasureRoles.Comments;
                    break;
            }

            if (foundRole) {
                return foundRole;
            }
        }

        // if target is not found, try to find it by using the :hover class (not working on Mac Os)
        document.querySelectorAll(".bucket:hover").forEach(hoveredItem => {
            switch (hoveredItem.className) {
                case this.bucketActual.node().className:
                    foundRole = MeasureRoles.Values;
                    break;
                case this.bucketPY.node().className:
                    foundRole = MeasureRoles.PreviousYear;
                    break;
                case this.bucketPL.node().className:
                    foundRole = MeasureRoles.Plan;
                    break;
                case this.bucketFC.node().className:
                    foundRole = MeasureRoles.Forecast;
                    break;
                case this.bucketComment.node().className:
                    foundRole = MeasureRoles.Comments;
                    break;
            }
        });
        return foundRole;
    }
}
