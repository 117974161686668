export const transformSharePointResponse = (values = []) => {
  const rows = (values ?? []).reduce((acc, values: [], index) => {
    acc[index] = {
      cells: values.reduce((acc2, value, index2) => {
        acc2[index2] = { text: value };
        return acc2;
      }, {}),
    };
    return acc;
  }, {});

  return { rows };
};

export const ROOT_ELEMENT_ID = "excel-data-linking";

export function changeRootDOMElementDisplay(display: "block" | "none") {
  const rootElement = document.getElementById(ROOT_ELEMENT_ID) as HTMLDivElement;

  if (rootElement) {
    rootElement.style.display = display;
  }
}
