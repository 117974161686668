import React, { useState, useEffect } from "react";
import { dataLinkingEventBus } from "../../DataLinkingEventBus";
import useStore from "../../useStore";
import SharepointBrowserAPI from "@zebrabi/sharepoint-api-browser/SharepointBrowserAPI";
import { changeRootDOMElementDisplay, transformSharePointResponse } from "../../helpers";
import { licensing } from "@zebrabi/licensing/Licensing";

export default function RangeSelection() {
  const [accessToken] = useStore("accessToken");
  const [activeSiteId, setActiveSiteId] = useStore("activeSiteId");
  const [activeDriveId, setActiveDriveId] = useStore("activeDriveId");
  const [activeWorksheetId, setActiveWorksheetId] = useStore("activeWorksheetId");
  const [activeWorkbookId] = useStore("activeWorkbookId");
  const [activeFileId, setActiveFileId] = useStore("activeFileId");
  const [activeFile] = useStore("activeFile");
  const [, setFoldersAndFiles] = useStore("foldersAndFiles");
  const [, setDrives] = useStore("drives");
  const [, setFoldersNavigation] = useStore("foldersNavigation");
  const [, setCurrentView] = useStore("currentView");
  const [loaded, setLoaded] = useStore("loaded");
  const [, setShowFullScreenLoader] = useStore("showFullScreenLoader");

  const [range, setRange] = useState<string>("");
  const [showErrorMessage, setShowErrorMessage] = useState<boolean>(false);

  const browser = SharepointBrowserAPI.createInstance(accessToken);

  useEffect(() => {
    setRange("");
  }, [activeWorksheetId, setRange]);

  const updateRange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value.replace(/\s/g, "").toUpperCase();

    setRange(value);
  };

  const persistRange = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setShowFullScreenLoader(true);
    setShowErrorMessage(false);

    const valuesResponse = activeWorkbookId
      ? await browser.getTableRange(activeDriveId, activeFileId, activeWorkbookId)
      : await browser.getRange(activeDriveId, activeFileId, activeWorksheetId, range);

    const autoRefresh = !!((event.target as HTMLFormElement)?.elements.namedItem("auto-refresh") as HTMLInputElement)?.checked;

    if (valuesResponse?.message) {
      setLoaded({
        siteId: activeSiteId,
        driveId: activeDriveId,
        fileId: activeFileId,
        worksheetId: activeWorksheetId,
        workbookId: activeWorkbookId,
        range,
        dataRefreshTimestamp: loaded?.dataRefreshTimestamp,
        autoRefresh: autoRefresh,
        errorMessage: valuesResponse.message,
      });
      setShowErrorMessage(true);
      setShowFullScreenLoader(false);
      return;
    }

    const rows = transformSharePointResponse(valuesResponse.values);

    setLoaded({
      siteId: activeSiteId,
      driveId: activeDriveId,
      fileId: activeFileId,
      worksheetId: activeWorksheetId,
      workbookId: activeWorkbookId,
      range,
      dataRefreshTimestamp: new Date(),
      autoRefresh: autoRefresh,
      errorMessage: null,
      linkCreator: licensing.getCurrentUser()?.getUserData(),
      linkSourceDataFile: activeFile,
    });

    dataLinkingEventBus.dispatch("range-selected", rows);

    // make sure these calls are the same as in CloseExcelDataLinking
    setShowFullScreenLoader(false);
    setFoldersAndFiles([]);
    setFoldersNavigation([]);
    setDrives([]);
    setActiveSiteId("");
    setActiveDriveId("");
    setActiveWorksheetId("");
    setActiveFileId("");
    setCurrentView("file-selection");

    changeRootDOMElementDisplay("none");
  };

  const isEnabled: boolean = activeWorkbookId || (activeWorksheetId && range);

  return (
    <form className="range-selection" onSubmit={persistRange}>
      {activeWorksheetId && <label htmlFor="range">Range:</label>}
      {activeWorksheetId && (
        <input type="text" placeholder="A1:D10" name="range" id="range" value={range} onChange={updateRange} />
      )}
      <button type="submit" disabled={!isEnabled}>
        Fetch data
      </button>
      <input type="checkbox" id="auto-refresh-id" name="auto-refresh" defaultChecked={loaded?.autoRefresh} value={loaded?.autoRefresh} />
      <label htmlFor="auto-refresh-id">Auto-update with latest Excel data on PowerPoint file open [Beta]</label>
      {showErrorMessage && <p className="error-message">An error occured: {loaded?.errorMessage}</p>}
    </form>
  );
}
