
import { valueFormatter } from "powerbi-visuals-utils-formattingutils";
import type { ChartSettings } from "../settings/chartSettings";
import { DataLabelsUnits, getFormattedDataLabel } from "@zebrabi/zebrabi-core";

export function getFormattedTooltipMeasureLabel(value: number | string, format: string, isDate: boolean): string {
    if (typeof value === "number") {
        const formatter = valueFormatter.create({ format: format });
        return formatter.format(value);
    }
    else if (isDate) {
        return fixDateTime(new Date(value), format, undefined);
    }
    else {
        return value;
    }
}

export function fixDateTime(value: any, format: string, locale: string) {
    const formatter = valueFormatter.create({ format: format, cultureSelector: locale });
    const dtValue = value instanceof Date ? value : new Date(value);
    return formatter.format(dtValue);
}

export function getVarianceDataLabel(value: number, decimalPlaces: number, displayUnits: DataLabelsUnits, locale: string, encloseNegativeValuesInParentheses: boolean, showPercentageSymbol: boolean,
    isRelativeVariance: boolean, encloseRelativeVarianceInParentheses: boolean, percentageFormat?: string, hideUnits: boolean = false): string {
    if (value == null) {
        return "";
    }
    return getFormattedDataLabel(value, decimalPlaces, displayUnits, locale, encloseNegativeValuesInParentheses, showPercentageSymbol, true, isRelativeVariance,
        encloseRelativeVarianceInParentheses, { percentageFormat: percentageFormat, pbiFormat: undefined }, hideUnits);
}

export function getRelativeVarianceLabel(settings: ChartSettings, difference: number, encloseInParentheses: boolean): string {
    const useParentheses = encloseInParentheses && !settings.showNegativeValuesInParenthesis();
    return getVarianceDataLabel(difference, settings.decimalPlacesPercentage, DataLabelsUnits.Relative, settings.locale,
        settings.showNegativeValuesInParenthesis(), settings.showPercentageInLabel, true, useParentheses);
}
