import {
  DefaultButton,
  ButtonStateTypes,
  DefaultButtonSubscriber,
  IconButton,
  InlineToggleButton,
  SplitButton,
  SplitButtonData,
  SplitButtonActions,
  SplitButtonSubscriber,
  IconName,
  ToggleButtonSubscriber,
  ToggleButtonData,
} from "@zebrabi/design-library";
import { dataLinkingEventBus } from "./DataLinkingEventBus";
import { mountReactExcelDataLinking } from "./react";
import { ExcelDataLinkingStore } from "./store";
import { signInService } from "@zebrabi/licensing/components/signin/MSFTSignIn";
import { licensing } from "@zebrabi/licensing/Licensing";
import SharepointBrowserAPI from "@zebrabi/sharepoint-api-browser/SharepointBrowserAPI";
import { transformSharePointResponse } from "./helpers";
import { openURL } from "@zebrabi/licensing/helpers";

export default class ExcelDataLinkingSwitcher {

  private readonly clickOutsideOverlayElement: HTMLDivElement = document.createElement("div"); // used for click event to close on click outside of dropdown menu
  private readonly buttonsDropdownMenu: HTMLDivElement = document.createElement("div");

  private dataRefreshDiv: HTMLDivElement;
  private lastDataRefreshText: HTMLDivElement;
  private errorMessageText: HTMLDivElement;
  private errorMessageDiv: HTMLDivElement;
  private dataLoadingDiv: HTMLDivElement;

  private linkData = new DefaultButton("Link data to Excel");
  
  private editLinkData = new DefaultButton("Edit link", ButtonStateTypes.text, {}, {
    iconBefore: IconName.EditLink
  });

  private unlinkData = new DefaultButton("Remove link", ButtonStateTypes.text, undefined, {
    iconBefore: IconName.RemoveLink,
  });

  private autoRefreshDataToggle: InlineToggleButton;

  private helpButton = new DefaultButton("Help", ButtonStateTypes.text, undefined, {
    iconBefore: IconName.QuestionCircle,
  });

  private splitButton: SplitButton = new SplitButton("Refresh data", ButtonStateTypes.text);

  public appendElementsToHtml(node: HTMLElement) {
    this.clickOutsideOverlayElement.classList.add("excel-data-linking-dropdown-menu-backdrop", "hidden");
    document.body.appendChild(this.clickOutsideOverlayElement);

    this.buttonsDropdownMenu.classList.add("excel-data-linking-dropdown-menu", "hidden");
    document.body.appendChild(this.buttonsDropdownMenu);
    this.buttonsDropdownMenu.addEventListener("click", (event) => event.stopPropagation());

    this.appendHandlerToLinkDataButton();
    this.appendHandlerToUnlinkDataButton();
    this.appendHandlerToEditLinkDataButton();
    this.appendHandlerToSplitButton();
    this.appendHandlerToHelpButton();
    
    const div = document.createElement("div");
    div.classList.add("excel-data-linking-buttons");
    node.appendChild(div);

    div.appendChild(this.createDataRefreshDiv());

    div.appendChild(this.createErrorMessageDiv());

    div.appendChild(this.createDataLoadingDiv());

    this.linkData.appendTo(div);

    this.splitButton.appendTo(div);
    this.editLinkData.appendTo(this.buttonsDropdownMenu);
    this.unlinkData.appendTo(this.buttonsDropdownMenu);
    this.buttonsDropdownMenu.appendChild(document.createElement("hr"));

    this.autoRefreshDataToggle = new InlineToggleButton(ExcelDataLinkingStore.get().loaded.autoRefresh, { label: "Auto-refresh [Beta]", name: "auto-refresh" });
    this.autoRefreshDataToggle.appendTo(this.buttonsDropdownMenu);
    this.autoRefreshDataToggle.containerNode().title = "Auto-update with latest Excel data on PowerPoint file open";
    this.appendHandlerToAutoRefreshDataToggle();

    const infoTextDiv = document.createElement("div");
    infoTextDiv.classList.add("data-linking-info-text");

    const icon = new IconButton(IconName.InfoCircle, false);
    icon.appendTo(infoTextDiv);

    const infoText = document.createElement("div");
    infoTextDiv.appendChild(infoText);
    infoText.textContent = "Data will refresh automatically when the PowerPoint file is opened. For the best experience, use the desktop version of Excel and manually save changes (with the Save button or Ctrl+S). Using Excel for the web or relying on AutoSave may cause delays in data updates.";
    this.buttonsDropdownMenu.appendChild(infoTextDiv);

    this.buttonsDropdownMenu.appendChild(document.createElement("hr"));

    this.helpButton.appendTo(this.buttonsDropdownMenu);
  }

  private createErrorMessageDiv(): HTMLDivElement {
    this.errorMessageDiv = document.createElement("div");
    this.errorMessageDiv.classList.add("refresh-error-message");

    const icon = new IconButton(IconName.ErrorCircle, false);

    icon.appendTo(this.errorMessageDiv);

    const textDiv = document.createElement("div");
    textDiv.appendChild(document.createElement("h6")).textContent = "Update failed:";
    this.errorMessageText = document.createElement("div");
    this.errorMessageText.classList.add("message-text");

    textDiv.appendChild(this.errorMessageText);

    this.errorMessageDiv.appendChild(textDiv);

    return this.errorMessageDiv;
  }

  private createDataRefreshDiv(): HTMLDivElement {
    this.dataRefreshDiv = document.createElement("div");
    this.dataRefreshDiv.classList.add("excel-data-linking-last-refreshed");

    const icon = new IconButton(IconName.CheckmarkCircle, false);
    icon.appendTo(this.dataRefreshDiv);

    const textDiv = document.createElement("div");
    textDiv.appendChild(document.createElement("h6")).textContent = "You're up to date"; // You're up to date  // Data last updated:

    this.lastDataRefreshText = document.createElement("div");
    this.lastDataRefreshText.classList.add("message-text");
    textDiv.appendChild(this.lastDataRefreshText);

    this.dataRefreshDiv.appendChild(textDiv);

    return this.dataRefreshDiv;
  }

  private createDataLoadingDiv(): HTMLDivElement {
    this.dataLoadingDiv = document.createElement("div");
    this.dataLoadingDiv.classList.add("excel-data-linking-loading");

    const icon = new IconButton(IconName.ArrowSync, false);
    icon.appendTo(this.dataLoadingDiv);

    const textDiv = document.createElement("div");
    textDiv.appendChild(document.createElement("h6")).textContent = "Updating...";

    // const progressBar = document.createElement("div");
    // progressBar.classList.add("progress-bar");
    // textDiv.appendChild(progressBar);

    this.dataLoadingDiv.appendChild(textDiv);

    return this.dataLoadingDiv;
  }

  appendHandlerToHelpButton() {
    const subscriber = new DefaultButtonSubscriber();
    subscriber.update = async (message) => {
      if (message.click) {
        (message.click as PointerEvent).preventDefault();
        const url = "https://help.zebrabi.com/kb/powerpoint/data-linking/";
        openURL(url);
      }
    };

    this.helpButton.subscribe(subscriber);
  }

  public updateButtonsStates() {
    const { worksheetId, workbookId, dataRefreshTimestamp, errorMessage, linkSourceDataFile, range } = ExcelDataLinkingStore.get().loaded;

    this.dataLoadingDiv.classList.add("hidden");

    //this.lastDataRefreshText.innerHTML = dataRefreshTimestamp ? `<span>Data last refreshed:</span><br/>${dataRefreshTimestamp.toLocaleString()}` : "";
    this.lastDataRefreshText.textContent = dataRefreshTimestamp ? `${dataRefreshTimestamp.toLocaleString()}` : "";    
    if (dataRefreshTimestamp) {
      this.dataRefreshDiv.classList.remove("hidden");
      this.dataRefreshDiv.title = linkSourceDataFile ? `Data source: ${linkSourceDataFile?.parentReference?.path}/${linkSourceDataFile.name}` : ""; //todo? \nupdated: ${new Date(linkSourceDataFile.lastModifiedDateTime).toLocaleString()}
    } else {
      this.dataRefreshDiv.classList.add("hidden");
    }

    if (errorMessage) {
      const source = linkSourceDataFile?.parentReference?.path + "/" + linkSourceDataFile?.name; //+ "-" + (workbookId ? "tableId:" + workbookId  : "range: " + range);
      this.errorMessageText.textContent = errorMessage;
      this.errorMessageDiv.title = `An error occurred while refreshing data [${source}]: ${errorMessage}`;

      this.dataRefreshDiv.classList.add("hidden");
      this.errorMessageDiv.classList.remove("hidden");
    } else {
      this.errorMessageDiv.classList.add("hidden");
    }

    // set autoRefreshDataToggle state
    const autoRefresh = ExcelDataLinkingStore.get().loaded.autoRefresh;
    // if (autoRefresh) {
    //   this.autoRefreshDataToggle.inputNode().setAttribute("checked", "checked")
    // } else {
    //   this.autoRefreshDataToggle.inputNode().removeAttribute("checked")
    // }
    this.autoRefreshDataToggle.inputNode().checked = autoRefresh;

    if (worksheetId || workbookId) {
      this.linkData.buttonNode().classList.add("hidden");
      this.splitButton.buttonNode().classList.remove("hidden");
    } else {
      this.linkData.buttonNode().classList.remove("hidden");
      this.splitButton.buttonNode().classList.add("hidden");
    }
  }

  private appendHandlerToLinkDataButton() {
    const subscriber = new DefaultButtonSubscriber();

    subscriber.update = async (message) => {
      if (message.click) {
        (message.click as PointerEvent).preventDefault();

        await signInService.authenticateWithFilePermissions(licensing.getCurrentUser()?.organizationId);
        mountReactExcelDataLinking(signInService.getSharepointToken());
      }
    };

    this.linkData.buttonNode().style.alignSelf = "center";
    this.linkData.subscribe(subscriber);
  }

  private appendHandlerToEditLinkDataButton() {
    const subscriber = new DefaultButtonSubscriber();

    subscriber.update = async (message) => {
      if (message.click) {
        (message.click as PointerEvent).preventDefault();
        (message.click as PointerEvent).stopPropagation();
        this.hideDropdownMenu();

        await signInService.authenticateWithFilePermissions(licensing.getCurrentUser()?.organizationId);
        mountReactExcelDataLinking(signInService.getSharepointToken());
      }
    };

    this.editLinkData.subscribe(subscriber);
  }

  private appendHandlerToUnlinkDataButton() {
    const subscriber = new DefaultButtonSubscriber();

    subscriber.update = (message) => {
      if (message.click) {
        (message.click as PointerEvent).preventDefault();
        (message.click as PointerEvent).stopPropagation();
        this.hideDropdownMenu();

        ExcelDataLinkingStore.set({
          loaded: {
            siteId: "",
            driveId: "",
            fileId: "",
            worksheetId: "",
            workbookId: "",
            range: "",
            dataRefreshTimestamp: null,
            errorMessage: null,
            autoRefresh: null,
          },
        });

        this.updateButtonsStates();
      }
    };

    this.unlinkData.subscribe(subscriber);
  }

  private appendHandlerToSplitButton() {
    const subscriber = new SplitButtonSubscriber();

    const loadRangeAndSetData = async (accessToken: string) => {
      const loaded = ExcelDataLinkingStore.get().loaded;
      const { siteId, driveId, fileId, worksheetId, workbookId, range, autoRefresh } = loaded;

      const browser = SharepointBrowserAPI.createInstance(accessToken);

      const response = workbookId
        ? await browser.getTableRange(driveId, fileId, workbookId)
        : await browser.getRange(driveId, fileId, worksheetId, range);

      if (response.message) {
        ExcelDataLinkingStore.set({
          loaded: {
            ...loaded,
            errorMessage: response.message,
          }
        });
        this.hideLoader();
        this.updateButtonsStates();
        return;
      }

      const rows = transformSharePointResponse(response.values);

      // set new data refresh timestamp
      ExcelDataLinkingStore.set({
        loaded: {
          ...loaded,
          errorMessage: null,
          dataRefreshTimestamp: new Date(),
        }
      });

      this.hideLoader();
      dataLinkingEventBus.dispatch("range-selected", rows);
    };

    subscriber.update = async (message: SplitButtonData, action: SplitButtonActions) => {
      if (message.click.type !== "click") {
        return;
      }

      if (action === SplitButtonActions.ButtonClick) {
        this.hideDropdownMenu();
        this.showLoader();
        // refresh data
        if (ExcelDataLinkingStore.get().accessToken) {
          await loadRangeAndSetData(ExcelDataLinkingStore.get().accessToken);
          return;
        }

        await signInService.authenticateWithFilePermissions(licensing.getCurrentUser()?.organizationId);
        await loadRangeAndSetData(signInService.getSharepointToken());

        return;
      }

      if (action === SplitButtonActions.IconClick) {
        this.showDropdownMenu();
      }
    };

    this.splitButton.subscribe(subscriber);
  }

  private appendHandlerToAutoRefreshDataToggle() {
    const subscriber = new ToggleButtonSubscriber();
    subscriber.update = (message: ToggleButtonData): void => {

      const loaded = ExcelDataLinkingStore.get().loaded;
      // set new auto refresh value
      ExcelDataLinkingStore.set({
        loaded: {
          ...loaded,
          autoRefresh: message.value,
        }
      });

      this.updateButtonsStates();
    };

    this.autoRefreshDataToggle.subscribe(subscriber);
  }

  private showLoader() {
    this.errorMessageDiv.classList.add("hidden");
    this.dataRefreshDiv.classList.add("hidden");
    this.dataLoadingDiv.classList.remove("hidden");
  }

  private hideLoader() {
    this.dataLoadingDiv.classList.add("hidden");
  }

  private hideDropdownMenu(event?: PointerEvent) {
    event?.stopPropagation();

    this.buttonsDropdownMenu.classList.add("hidden");

    this.clickOutsideOverlayElement.classList.add("hidden");
    this.clickOutsideOverlayElement.removeEventListener("click", this.hideDropdownMenu.bind(this));
  }

  private showDropdownMenu() {
    const { right, bottom } = this.splitButton.buttonNode().getBoundingClientRect();
    const rightOffset = Math.round(document.body.getBoundingClientRect().width - right);

    this.buttonsDropdownMenu.style.top = `${bottom}px`;
    this.buttonsDropdownMenu.style.right = `${rightOffset}px`;
    this.buttonsDropdownMenu.classList.remove("hidden");

    this.clickOutsideOverlayElement.classList.remove("hidden");
    this.clickOutsideOverlayElement.addEventListener("click", this.hideDropdownMenu.bind(this));
  }
}
