import { DataView } from "@zebrabi/table-data";
import type { ViewModel, Viewport } from "./interfaces";
import { ChartSettings } from "./settings/chartSettings";

export class GlobalChartsState {
    public static dataView: DataView;
    public static viewModel: ViewModel;
    public static settings: ChartSettings;  //todo: remove these static properties, use ones from instance??
    static visualViewPort: Viewport;
    public static element: HTMLElement; //todo: remove this?

}
