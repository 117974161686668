import { analyticsHandler } from "@zebrabi/analytics-tracking/AnaliticsHandler";
import React, { useEffect } from "react";
import useStore from "@zebrabi/chart-chooser/useStore";
import { categories, chartsMap } from "../../dimensions";
import { ChartCategoryList } from "../ChartCategoryList";
import { ChartOptionCard } from "../ChartOptionCard";
import {
  concatCommentsToSheetData,
  setXSpreadSheetData,
  xSpreadsheetToTableData,
  xSpreadsheetToTableDataforSmallMultiples,
} from "@zebrabi/table-data-editor";
import { GlobalChartsState } from "../../../../../../../packages/visuals/charts/chartsGlobalState";
import { CHART_SETTINGS_NAME } from "../../../../../../../packages/visuals/charts/settings/chartSettings";
import { updateSpreadsheet } from "@zebrabi/table-data-editor/x-spreadsheet";
import { getOfficeSettings } from "@zebrabi/office-settings";

/* global Office */

export default function ChartCategories() {
  const [selectedDimension] = useStore<string>("selectedDimension");
  const [selectedChart, setSelectedChart] = useStore<string>("selectedChart");
  const [isVisible, setIsVisible] = useStore<boolean>("isVisible");
  const [areComments] = useStore<boolean>("areComments");

  useEffect(() => {
    if (!selectedChart) return;
    setVisualChart();
    const sheetData =
      areComments && !chartsMap[selectedChart].disabledComments
        ? concatCommentsToSheetData(chartsMap[selectedChart].sampleData, chartsMap[selectedChart].commentData)
        : chartsMap[selectedChart].sampleData;
    setXSpreadSheetData(
      chartsMap[selectedChart].isMultiples
        ? xSpreadsheetToTableDataforSmallMultiples(sheetData)
        : xSpreadsheetToTableData(sheetData)
    );

    updateSpreadsheet(sheetData);
    setIsVisible(false);
    analyticsHandler.report(`chooser/${selectedChart}`);
  }, [selectedChart]);

  const setVisualChart = () => {
    const newSettings =
    {
        ...getOfficeSettings(CHART_SETTINGS_NAME),
        ...(areComments && !chartsMap[selectedChart].disabledComments
          ? { showCommentBox: true }
          : { showCommentBox: false }),
    };

    Object.keys(chartsMap[selectedChart].settings).forEach((key) => {
      newSettings[key] = chartsMap[selectedChart].settings[key];
    });

    Office.context.document.settings.set(CHART_SETTINGS_NAME, newSettings);
    GlobalChartsState.settings = newSettings;
  };

  return categories[selectedDimension].map((series: any, index: number) => (
    <ChartCategoryList title={series.displayName} key={`ChartCategoryList-${index}`}>
      {series.charts.map((chart: any, index: number) => (
        <ChartOptionCard
          chart={chart}
          onSelect={setSelectedChart}
          isSelected={selectedChart === chart.id}
          key={`ChartOptionCard-${index}`}
        />
      ))}
    </ChartCategoryList>
  ));
}
