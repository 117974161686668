import GlobalToolbarObserver from "@zebrabi/global-toolbar-old/observers/GlobalToolbarObserver";
import { GlobalChartsState } from "../../chartsGlobalState";

//import {ChartChooserStore} from "@zebrabi/chart-chooser";

/**
 * Type defined observer used to isolate the notifications
 */
class StackedChartObserver extends GlobalToolbarObserver {
    static readonly CLASS_NAME = "StackedChartObserver";

    getClassName(): string {
        return StackedChartObserver.CLASS_NAME;
    }

    /**
     * Triggered on general actions (open, close, save, etc.)
     * @param action
     * @param message
     */
    action(action: string, message: string): void {
        GlobalChartsState.settings.stackedChart = !GlobalChartsState.settings.stackedChart;
        GlobalChartsState.settings.persistStackedChartChanges();
    }

    /**
     * triggered when the VisualSwitcher changes data
     * @param formData
     */
    update(formData: Map<string, any>): void {
        return;
    }
}

export default StackedChartObserver;
