import { Categories } from "../category.type";
import TimeComparisonColumnImage from "./images/time-comparison-column.svg";
import TimeComparisonLineImage from "./images/time-comparison-line.svg";
import TimeComparisonAreaImage from "./images/time-comparison-area.svg";
import TimeComparisonDotImage from "./images/time-comparison-dot.svg";
import TimeSeriesComparisonColumnImage from "./images/time-series-comparison-column.svg";
import TimeSeriesComparisonLineImage from "./images/time-series-comparison-line.svg";
import TimeSmallMultiples1Image from "./images/time-small-multiples-1.svg";
import TimeSeriesComparisonDotImage from "./images/time-series-comparison-dot.svg";
import TimeSmallMultiples2Image from "./images/time-small-multiples-2.svg";

import {
  TimeComparisonAreaCommentData,
  TimeComparisonAreaSampleData,
  TimeComparisonColumnCommentData,
  TimeComparisonColumnSampleData,
  TimeComparisonDotCommentData,
  TimeComparisonDotSampleData,
  TimeComparisonLineCommentData,
  TimeComparisonLineSampleData,
  TimeSeriesComparisonColumnCommentData,
  TimeSeriesComparisonColumnSampleData,
  TimeSeriesComparisonLineCommentData,
  TimeSeriesComparisonLineSampleData,
  TimeSmallMultiples1SampleData,
} from "./timeCategories.mock";
import { ChartType } from "@zebrabi/charts";
import { ACTUAL } from "@zebrabi/charts/dist/lib/consts";

export const timeCategories: Categories = [
  {
    displayName: "Single Series",
    charts: [
      {
        id: "time-comparison-column",
        title: "Column chart",
        image: TimeComparisonColumnImage,
        sampleData: TimeComparisonColumnSampleData,
        commentData: TimeComparisonColumnCommentData,
        tooltip:
          "Look up specific values, compare two or more values and observe trends from left to right, visualize how absolute measures changes. Use when data category represents time (years, months).",
        settings: {
          chartType: ChartType.Bar,
          showVerticalCharts: false,
        },
      },
      {
        id: "time-comparison-line",
        title: "Line chart",
        image: TimeComparisonLineImage,
        sampleData: TimeComparisonLineSampleData,
        commentData: TimeComparisonLineCommentData,
        tooltip:
          "Look up specific values, compare two or more values and observe trends from left to right, analyze the pattern of any dependent variable over time.",
        settings: {
          chartType: ChartType.Line,
          showVerticalCharts: false,
        },
      },
      {
        id: "time-comparison-area",
        title: "Area chart",
        image: TimeComparisonAreaImage,
        sampleData: TimeComparisonAreaSampleData,
        commentData: TimeComparisonAreaCommentData,
        tooltip:
          "Look up specific values, compare two or more values and observe trends from left to right, analyze trends and relationships with summation Use when data category represents time (years, months).",
        settings: {
          chartType: ChartType.Area,
          showVerticalCharts: false,
        },
      },
      {
        id: "time-comparison-dot",
        title: "Horizontal pin chart",
        image: TimeComparisonDotImage,
        sampleData: TimeComparisonDotSampleData,
        commentData: TimeComparisonDotCommentData,
        tooltip:
          "Look up specific values, compare two or more values and observe trends from left to right. Use when data category represents time (years, months), suitable for percentage measures.",
        settings: {
          chartType: ChartType.Pin,
          showVerticalCharts: false,
        },
      },
    ],
  },
  {
    displayName: "Multiple Series",
    charts: [
      {
        id: "time-series-comparison-column",
        title: "Comparison column chart",
        image: TimeSeriesComparisonColumnImage,
        sampleData: TimeSeriesComparisonColumnSampleData,
        commentData: TimeSeriesComparisonColumnCommentData,
        tooltip:
          "Compare multiple data series(AC, PY, Plan, FC), look up specific values, compare and observe trends from left to right, and visualize how data categories and their totals change when the data category represents time (years, months).",
        settings: {
          chartType: ChartType.Variance,
          showVerticalCharts: false,
          chartLayout: ACTUAL,
        },
      },
      {
        id: "time-series-comparison-line",
        title: "Comparison line chart",
        image: TimeSeriesComparisonLineImage,
        sampleData: TimeSeriesComparisonLineSampleData,
        commentData: TimeSeriesComparisonLineCommentData,
        tooltip:
          "Compare multiple data series(AC, PY, Plan, FC), look up specific values, visualize multiple lines and compare the trend between the series. Use when data category represents time (years, months).",
        settings: {
          chartType: ChartType.Line,
          showVerticalCharts: false,
        },
      },
      /*
      {
        id: "time-series-comparison-dot",
        title: "Series comparison chart",
        image: TimeSeriesComparisonDotImage,
        sampleData: chartSampleDataHorizontal,
        settings: {
          chartType: ChartType.Pin,
          showVerticalCharts: false,
        }
      },
      */
    ],
  },
  {
    displayName: "Small multiples",
    charts: [
      {
        id: "time-small-multiples-1",
        title: "Small multiples column chart",
        image: TimeSmallMultiples1Image,
        sampleData: TimeSmallMultiples1SampleData,
        isMultiples: true,
        disabledComments: true,
        tooltip:
          "Also called the Trellis chart, extremely valuable visual comparisons in a single report. Use to compare values and trends of different categories in one visual.",
        settings: {
          chartType: ChartType.Bar,
          showVerticalCharts: false,
        },
      },
      /*
      {
        id: "time-small-multiples-2",
        title: "Series comparison chart",
        image: TimeSmallMultiples2Image,
        sampleData: smallMultiplesSampleData,
        settings: {},
      },
      */
    ],
  },
];
