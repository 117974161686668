import type { ColorScheme } from "./../library/interfaces";
import { BLACK, LEFT, EMPTY, DEFAULT_FONT, NORMAL, HIGHLIGHT_COLOR, GRAY, TRANSPARENT, FONT_SIZE_UNIT } from "./../library/constants";

import { persistManagerInstance } from "@zebrabi/office-settings/PersistManager";
import { MeasureRoles } from "@zebrabi/data-helpers/fieldAssignment";
import {
    AxisLabelDensity, ChartType, DifferenceHighlightArrowStyle, DifferenceHighlightEllipse, DifferenceHighlightFromTo, DotChartMarkerShape, GridlineStyle, LabelDensity,
    MarkerSize, MultiplesAxisLabelsOptions, NegativeValuesFormat, ReferenceDisplayType, ShowTopNChartsOptions, Sort, ChartsViewMode as ViewMode }
from "@zebrabi/charts";

import type { OrganizationStyleData } from "@zebrabi/data-helpers/organizationStyles";
import { DataLabelsUnits } from "@zebrabi/zebrabi-core";
import type { ChartLayoutOptions, DataLabelSettings, DifferenceHighlightSettings, FontSizeUnit, GlobalCategorySettings, GlobalLegendSettings, GlobalStackedChartSettings, MultiplesLayoutTypes, ScenarioOptions } from "@zebrabi/charts/dist/lib/typings";
import {
    CategoryDisplayOptions, CategoryLabelsOptions, CategoryWidthOptions, ChartStyle, CommentBoxPlacement, CommentBoxTitle, CommentBoxVariance,
    DataLabelUnitOptions, DifferenceLabel, Scenario, VarianceDisplayType, VarianceIcon }
from "@zebrabi/constants";
import { LARGEST_FIRST_LAYOUT, RESPONSIVE, ROWS_LAYOUT } from "@zebrabi/charts/dist/lib/consts";
import { TitleStyle } from "@zebrabi/charts/dist/lib/charting/title";

export const CHART_SETTINGS_NAME = "ChartSettings";

export class ChartSettings {
    public readonly viewMode: ViewMode = ViewMode.Edit;
    public readonly locale: string;
    public readonly fontSizeUnit: FontSizeUnit = FONT_SIZE_UNIT;

    // Pro features settings needed for charts package;
    public proFeaturesEnabled: boolean = true;
    public proFeaturesUnlocked: boolean = true;

    // Chart settings
    public invert: boolean = false;
    public chartLayout: ChartLayoutOptions = RESPONSIVE;
    public showVerticalCharts: boolean = false;

    public showGrandTotal: boolean = false;
    public grandTotalLabel: string = "Total";
    public referenceDisplayType: ReferenceDisplayType = ReferenceDisplayType.OverlappedColumn;
    public plotOverlappedReference: boolean = true;
    public handleNullsAsZeros: boolean = false;
    public limitOutliers: boolean = false;
    public minOutlierValue: number = null;
    public maxOutlierValue: number = null;
    public gapBetweenColumnsPercent: number = 20;
    public valueChartIntegrated: boolean = false;
    public suppressEmptyColumns: boolean = false;
    public showAllForecastData: boolean = false;

    public currentPeriodVarianceOptions: number = 0;
    public currentPeriodVarianceCondition: number = 0;
    public dayInMonthVarianceCondition: number = 15;
    public dayInWeekVarianceCondition: number = 4;
    public monthInQuarterVarianceCondition: number = 2;
    public monthInYearVarianceCondition: number = 7;

    // Comment Box
    public showCommentBox: boolean = true;
    public commentBoxTitle: CommentBoxTitle = CommentBoxTitle.TitleValueVariance;
    public commentBoxCustomTitleStyle: boolean = false;
    public commentBoxTitleFontSize: number = 18;
    public commentBoxTitleFontFamily: string = DEFAULT_FONT;
    public commentBoxTitleFontColor: string = BLACK;
    public commentBoxCustomTextStyle: boolean = false;
    public commentBoxTextFontSize: number = 16;
    public commentBoxTextFontFamily: string = DEFAULT_FONT;
    public commentBoxTextFontColor: string = BLACK;
    public commentBoxPlacement: CommentBoxPlacement = CommentBoxPlacement.Right;
    public commentBoxListHorizontal: boolean = false;
    public commentBoxSize: string = "0.66";
    public commentBoxPadding: number = 10;
    public commentBoxItemsMargin: number = 10;
    public commentBoxBorderWidth: number = 0;
    public commentBoxBorderColor: string = GRAY;
    public commentBoxBorderRadius: number = 0;
    public commentBoxShadow: boolean = false;
    public commentBoxBackgroundColor: string = TRANSPARENT;
    public commentBoxShowVariance: CommentBoxVariance = CommentBoxVariance.RelativeVariance;
    public commentBoxVarianceIcon: VarianceIcon = VarianceIcon.Triangle;
    public commentBoxExpandedGroup: string = EMPTY;
    public commentBoxUserChangedExpandedGroup: boolean = false;

    // Stacked chart
    public stackedChart: boolean = false;
    public stackedChartColors: number = 0;
    public d3ColorScheme: number = 1;
    public showTopNStackedOptions: ShowTopNChartsOptions = ShowTopNChartsOptions.Items;
    public topNStackedToKeep: number = 5;
    public topNStackedPercentage: number = 80;
    public topNStackedOthersLabel: string = "Others";
    public stackedAreaOpacity: number = 67;
    public showStackedLabelsAsPercentage: boolean = false;
    public stackedChartSort: Sort = Sort.Descending;

    // Title
    public showTitle: boolean = true;
    public titleText: string = EMPTY;
    public titleWrap: boolean = true;
    public titleFontColor: string = BLACK;
    public titleAlignment: string = LEFT;
    public titleFontSize: number = 12;
    public titleFontFamily: string = DEFAULT_FONT;

    // Group title
    public groupTitleFontColor: string = BLACK;
    public groupTitleAlignment: string = LEFT;
    public groupTitleVerticalAlignment: string = "Auto";
    public groupTitleFontSize: number = 12;
    public groupTitleFontFamily: string = DEFAULT_FONT;

    // Data labels
    public showDataLabels: boolean = true;
    public showReferenceLabels: boolean = false;
    public varianceLabel: DifferenceLabel = DifferenceLabel.Absolute;
    public labelFontColor: string = BLACK;
    public displayUnits: DataLabelsUnits = DataLabelsUnits.Auto;
    public showUnits: DataLabelUnitOptions = DataLabelUnitOptions.DataLabels;
    public decimalPlaces: number = 1;
    public decimalPlacesPercentage: number = 1;
    public labelDensity: LabelDensity = LabelDensity.Auto;
    public labelPercentagePointUnit: string = "pp";
    public labelFontSize: number = 10;
    public labelFontFamily: string = DEFAULT_FONT;
    public labelBackgroundTransparency: number = 20;
    public negativeValuesFormat: NegativeValuesFormat = NegativeValuesFormat.Default;
    public isPercentageData: boolean = false;
    public showPercentageInLabel: boolean = true;

    // Dot chart data labels
    public showDotChart: boolean = true;
    public dotChartFontColor: string = "#4080FF";
    public dotChartDisplayUnits: DataLabelsUnits = DataLabelsUnits.Auto;
    public dotChartDecimalPlaces: number = 1;
    public dotChartLabelDensity: LabelDensity = LabelDensity.FirstLastMinMax;
    public isDotChartPercentageData: boolean = false;
    public dotChartMaxHeightPercent: number = 90;
    public dotChartLineWidth: number = 1;
    public dotChartLineStyle: GridlineStyle = GridlineStyle.Solid;
    public dotChartDroplineWidth: number = 0;
    public dotChartDroplineColor: string = "#4080FF";
    public dotChartMarkerSizing: MarkerSize = MarkerSize.Auto;
    public dotChartMarkerFixedSize: number;
    public dotChartMarkerDensity: LabelDensity = LabelDensity.Auto;
    public dotChartMarkerShape: DotChartMarkerShape = DotChartMarkerShape.Circle;

    // Categories
    public showCategories: boolean = true;
    public categoryWidth: CategoryWidthOptions = CategoryWidthOptions.Auto;
    public verticalCategoriesDisplay: CategoryDisplayOptions = CategoryDisplayOptions.Auto;    // vertical charts only
    public categoryMinWidth: number = 35;
    public categoryLabelsOptions: CategoryLabelsOptions = CategoryLabelsOptions.Trim;
    public axisLabelDensity: AxisLabelDensity = AxisLabelDensity.All;
    public axisLabelDensityEveryNthLabel: number = 5;
    public categoryRotateAngle: number = 0;
    public categoryRotateAngleLimit: number = 70;
    public rotatedCartegoriesHeight: number = 0;
    public showTopNCategories: boolean = false;
    public topNCategoriesToKeep: number = 5;
    public showCategoriesFontSettings: boolean = false;
    public categoriesFontColor: string;
    public categoriesFontSize: number;
    public categoriesFontFamily: string;
    public topNOtherLabel: string = "Others";

    // Legend
    public showLegend: boolean = true;
    public switchReferenceScenarios: boolean = false;
    public legendFontColor: string;

    public valueHeader: string = null;
    public absoluteDifferenceHeader: string = null;
    public relativeDifferenceHeader: string = null;
    public referenceHeader: string = null;
    public secondReferenceHeader: string = null;
    public secondAbsoluteDifferenceHeader: string = null;
    public secondRelativeDifferenceHeader: string = null;
    public secondValueHeader: string = null;

    public actual: string = null;
    public previousYear: string = null;
    public forecast: string = null;
    public plan: string = null;
    public actual_previousYear: string = null;
    public actual_previousYear_percent: string = null;
    public actual_forecast: string = null;
    public actual_forecast_percent: string = null;
    public actual_plan: string = null;
    public actual_plan_percent: string = null;
    public forecast_previousYear: string = null;
    public forecast_previousYear_percent: string = null;
    public forecast_plan: string = null;
    public forecast_plan_percent: string = null;
    public previousYear_plan: string = null;
    public previousYear_plan_percent: string = null;
    public defaultScenarioHeaders: object;
    public useAliasesInTooltips: boolean = false;
    public useColoredLegendNames: boolean = false;
    public legendItemsMargin: number = 0;

    // Design
    public chartStyle: ChartStyle;
    public lightenOverlapped: boolean = true;
    public dotChartLineTransparency: number = 0;

    public colorScheme: ColorScheme = {
        positiveColor: "#7aca00",
        negativeColor: "#ff0000",
        neutralColor: "#404040",
        markerColor: BLACK,
        lineColor: "#404040",
        axisColor: BLACK,
        gridlineColor: "#cccccc",
        majorGridlineColor: "#cccccc",
        dotChartColor: "#4080FF",
        useCustomScenarioColors: false,
        previousYearColor: BLACK,
        planColor: BLACK,
        forecastColor: BLACK,
        applyPatterns: true,
        highlightColor: HIGHLIGHT_COLOR,
    };

    public areaNeutralOpacity: number = 20;
    public areaActualOpacity: number = 34;
    public areaVarianceOpacity: number = 100;
    public varianceDisplayType: VarianceDisplayType = VarianceDisplayType.Bar;
    public referenceMarkerSize: MarkerSize = MarkerSize.Auto;

    public referenceMarkerFixedSize: number;

    public selectedOrganizationStyleId: number;
    public availableOrganizationStyles: OrganizationStyleData[];    //TODO console error

    // Interaction
    public allowInteractions: boolean = true;
    public showChartSlider: boolean = true;
    public allowVarianceCalculationChange: boolean = true;
    public allowDifferenceHighlightChange: boolean = true;
    public allowAxisBreakChange: boolean = true;
    public readonly focusModeFontZoomPercentage: number = 150;   // probably not needed in Office
    public enableMeasureDrillThrough: boolean = false;
    public enableStackedChartIconInViewMode: boolean = true;
    public allowInteractiveCommentBox: boolean = true;
    public allowInteractiveLegendSettings: boolean = true;

    // Small multiples
    public multiplesLayoutType: MultiplesLayoutTypes = ROWS_LAYOUT;
    public multiplesSort: Sort = Sort.Descending;
    public multiples2dRowsSort: Sort = Sort.Descending;
    public multiples2dColumnsSort: Sort = Sort.Descending;
    public showTopNChartsOptions: ShowTopNChartsOptions = ShowTopNChartsOptions.Off;
    public topNChartsToKeep: number = 10;
    public topNChartsPercentage: number = 80;
    public topNChartsOthersLabel: string = "Others";
    public showMultiplesGrid: boolean = false;
    public multiplesGridlinesColor: string;
    public gridlineStyle: GridlineStyle = GridlineStyle.Solid;
    public showOuterBorders: boolean = true;
    public multiplesAxisLabelsOptions: MultiplesAxisLabelsOptions = MultiplesAxisLabelsOptions.AllCharts;
    public zoomedChartBackgroundColor: string = "#fff";

    // Difference highlight
    public differenceHighlight: boolean = true;
    public differenceHighlightFromTo: DifferenceHighlightFromTo = DifferenceHighlightFromTo.Auto;
    public differenceLabel: DifferenceLabel = DifferenceLabel.Relative;
    public differenceHighlightLineWidth: number = 2;
    public differenceHighlightArrowStyle: DifferenceHighlightArrowStyle = DifferenceHighlightArrowStyle.ClosedArrow;
    public differenceHighlightConnectingLineColor: string = "#808080";
    public differenceHighlightConnectingLineStyle: GridlineStyle = GridlineStyle.Solid;
    public differenceHighlightCustomFont: boolean = false;
    public differenceHighlightFontSize: number = 10;
    public differenceHighlightFontFamily: string = DEFAULT_FONT;
    public differenceHighlightEllipse: DifferenceHighlightEllipse = DifferenceHighlightEllipse.Off;
    public differenceHighlightEllipseBorderWidth: number = 1;
    public differenceHighlightEllipseBorderPadding: number = 4;
    public differenceHighlightEllipseFillOpacity: number = 10;
    public differenceHighlightMargin: number = 0;
    public showDifferenceHighlightSubtotals: boolean = true;

    // Axis break
    public hasAxisBreak: boolean = false;
    public axisBreakPercent: number = 80;

    // internal
    public chartType: ChartType = ChartType.Waterfall;
    public scenarioOptions: ScenarioOptions;
    public multilineCategories: boolean = false;

    public invertedGroups: string[] = [];
    public highlightedGroups: string[] = [];
    public highlightedGroupsCustomColors: object[] = [];
    public invertedCategories: string[] = [];
    public resultCategories: string[] = [];
    public scenarioCategories: object[] = [];
    public highlightedCategories: string[] = [];
    public highlightedCategoriesCustomColors: object[] = [];
    public lockedChartTypesViewMode: string[] = [];

    public differenceHighlightWidth: number = 0;
    public minChartHeight: number = null;

    // Office specific settings
    public measure1Role: string = MeasureRoles.Values;
    public measure2Role: string = MeasureRoles.PreviousYear;
    public measure3Role: string = MeasureRoles.Plan;
    public measure4Role: string = MeasureRoles.Forecast;
    public measure5Role: string = MeasureRoles.Comments;
    // measeureXRole properties are deprecated, use measureRoles instead
    public measureRoles: MeasureRoles[] = [];

    public usedMeasuresCount: number;

    public enableFiltering: boolean;

    // missing/unused charts Office settings:
    public titleFontStyle: string = NORMAL;
    public titleFontWeight: string = NORMAL;
    public parseTitleTags: boolean = false;
    filterContexts: any[] = [];   //FilterContext[];
    annotationComments: any[] = []; //AnnotationComment[];
    floatingResultCategories: string[] = [];
    visualCreatedVersion: string = null;
    colorPalette: any;
    annotationCagrArrows: any;
    
    private persistCallback: (settings: ChartSettings) => void;

    constructor(showCompanyStyle: boolean, locale: string, scenarioOptions: ScenarioOptions, orgStyleSettings?: OrganizationStyleData) {
        this.locale = locale;

        this.enableFiltering = Office.context.host === Office.HostType.Excel;

        this.chartStyle = showCompanyStyle ? ChartStyle.Company : ChartStyle.Zebra;

        this.categoriesFontColor = this.labelFontColor;
        this.categoriesFontSize = this.labelFontSize;
        this.categoriesFontFamily = this.labelFontFamily;
        this.legendFontColor = this.labelFontColor;
        this.dotChartMarkerFixedSize = this.labelFontSize;
        this.referenceMarkerFixedSize = this.labelFontSize;
        this.multiplesGridlinesColor = this.colorScheme.gridlineColor;

        this.scenarioOptions = scenarioOptions;

        if (scenarioOptions && scenarioOptions.valueScenario !== null && scenarioOptions.referenceScenario === null) {  // chart type defaults to bar for single measure
            this.chartType = ChartType.Bar;
        }

        // Overwrite settings with organization style if available
        // todo: check if this needs to be set in settings constructor
        if (orgStyleSettings) {
            this.setOrganizationStyleSettings(orgStyleSettings);
        }
    }

    public setPersistCallback(callback: (settings: ChartSettings) => void) {
        this.persistCallback = callback;
    }

    // eslint-disable-next-line max-lines-per-function
    // todo: check if this is actually needed
    public setNewScenarioLegendValues() {
        this.actual_previousYear = this["actual-previousYear"] ?? this.actual_previousYear;
        this.actual_previousYear_percent = this["actual-previousYear-percent"] ?? this.actual_previousYear_percent;
        this.actual_plan = this["actual-plan"] ?? this.actual_plan;
        this.actual_plan_percent = this["actual-plan-percent"] ?? this.actual_plan_percent;
        this.actual_forecast = this["actual-forecast"] ?? this.actual_forecast;
        this.actual_forecast_percent = this["actual-forecast-percent"] ?? this.actual_forecast_percent;
        this.previousYear_plan = this["previousYear-plan"] ?? this.previousYear_plan;
        this.previousYear_plan_percent = this["previousYear-plan-percent"] ?? this.previousYear_plan_percent;
        this.forecast_previousYear = this["forecast-previousYear"] ?? this.forecast_previousYear;
        this.forecast_previousYear_percent = this["forecast-previousYear-percent"] ?? this.forecast_previousYear_percent;
        this.forecast_plan = this["forecast-plan"] ?? this.forecast_plan;
        this.forecast_plan_percent = this["forecast-plan-percent"] ?? this.forecast_plan_percent;
        const valueScenario = this.scenarioOptions.valueScenario;
        if (valueScenario !== null) {
            if (valueScenario === Scenario.Actual) {
                if (this.actual) {
                    this.valueHeader = this.actual;
                }
                else {
                    this.actual = this.valueHeader;
                }
            }
            else if (valueScenario === Scenario.PreviousYear) {
                if (this.previousYear) {
                    this.valueHeader = this.previousYear;
                }
                else {
                    this.previousYear = this.valueHeader;
                }
            }
            else if (valueScenario === Scenario.Plan) {
                if (this.plan) {
                    this.valueHeader = this.plan;
                }
                else {
                    this.plan = this.valueHeader;
                }
            }
            else if (valueScenario === Scenario.Forecast) {
                if (this.forecast) {
                    this.valueHeader = this.forecast;
                }
                else {
                    this.forecast = this.valueHeader;
                }
            }
        }

        const referenceScenario = this.scenarioOptions.referenceScenario;
        if (referenceScenario !== null) {
            if (referenceScenario === Scenario.PreviousYear) {
                if (this.previousYear) {
                    this.referenceHeader = this.previousYear;
                }
                else {
                    this.previousYear = this.referenceHeader;
                }

                if (valueScenario === Scenario.Actual) {
                    if (this.actual_previousYear) {
                        this.absoluteDifferenceHeader = this.actual_previousYear;
                    }
                    else {
                        this.actual_previousYear = this.absoluteDifferenceHeader;
                    }
                    if (this.actual_previousYear_percent) {
                        this.relativeDifferenceHeader = this.actual_previousYear_percent;
                    }
                    else {
                        this.actual_previousYear_percent = this.relativeDifferenceHeader;
                    }
                }
                else if (valueScenario === Scenario.Forecast) {
                    if (this.forecast_previousYear) {
                        this.absoluteDifferenceHeader = this.forecast_previousYear;
                    }
                    else {
                        this.forecast_previousYear = this.absoluteDifferenceHeader;
                    }
                    if (this.forecast_previousYear_percent) {
                        this.relativeDifferenceHeader = this.forecast_previousYear_percent;
                    }
                    else {
                        this.forecast_previousYear_percent = this.relativeDifferenceHeader;
                    }
                }
            }
            else if (referenceScenario === Scenario.Plan) {
                if (this.plan) {
                    this.referenceHeader = this.plan;
                }
                else {
                    this.plan = this.referenceHeader;
                }

                if (valueScenario === Scenario.Actual) {
                    if (this.actual_plan) {
                        this.absoluteDifferenceHeader = this.actual_plan;
                    }
                    else {
                        this.actual_plan = this.absoluteDifferenceHeader;
                    }
                    if (this.actual_plan_percent) {
                        this.relativeDifferenceHeader = this.actual_plan_percent;
                    }
                    else {
                        this.actual_plan_percent = this.relativeDifferenceHeader;
                    }
                }
                else if (valueScenario === Scenario.Forecast) {
                    if (this.forecast_plan) {
                        this.absoluteDifferenceHeader = this.forecast_plan;
                    }
                    else {
                        this.forecast_plan = this.absoluteDifferenceHeader;
                    }
                    if (this.forecast_plan_percent) {
                        this.relativeDifferenceHeader = this.forecast_plan_percent;
                    }
                    else {
                        this.forecast_plan_percent = this.relativeDifferenceHeader;
                    }
                }
                else if (valueScenario === Scenario.PreviousYear) {
                    if (this.previousYear_plan) {
                        this.absoluteDifferenceHeader = this.previousYear_plan;
                    }
                    else {
                        this.previousYear_plan = this.absoluteDifferenceHeader;
                    }

                    if (this.previousYear_plan_percent) {
                        this.relativeDifferenceHeader = this.previousYear_plan_percent;
                    }
                    else {
                        this.previousYear_plan_percent = this.relativeDifferenceHeader;
                    }
                }
            }
            else if (referenceScenario === Scenario.Forecast) {
                if (this.forecast) {
                    this.referenceHeader = this.forecast;
                }
                else {
                    this.forecast = this.referenceHeader;
                }

                if (this.actual_forecast) {
                    this.absoluteDifferenceHeader = this.actual_forecast;
                }
                else {
                    this.actual_forecast = this.absoluteDifferenceHeader;
                }
                if (this.actual_forecast_percent) {
                    this.relativeDifferenceHeader = this.actual_forecast_percent;
                }
                else {
                    this.actual_forecast_percent = this.relativeDifferenceHeader;
                }
            }
        }

        const secondReferenceScenario = this.scenarioOptions.secondReferenceScenario;
        if (secondReferenceScenario !== null) {
            if (secondReferenceScenario === Scenario.PreviousYear) {
                if (this.previousYear) {
                    this.secondReferenceHeader = this.previousYear;
                }
                else {
                    this.previousYear = this.secondReferenceHeader;
                }

                if (valueScenario === Scenario.Actual) {
                    if (this.actual_previousYear) {
                        this.secondAbsoluteDifferenceHeader = this.actual_previousYear;
                    }
                    else {
                        this.actual_previousYear = this.secondAbsoluteDifferenceHeader;
                    }
                    if (this.actual_previousYear_percent) {
                        this.secondRelativeDifferenceHeader = this.actual_previousYear_percent;
                    }
                    else {
                        this.actual_previousYear_percent = this.secondRelativeDifferenceHeader;
                    }
                }
                else if (valueScenario === Scenario.Forecast) {
                    if (this.forecast_previousYear) {
                        this.secondAbsoluteDifferenceHeader = this.forecast_previousYear;
                    }
                    else {
                        this.forecast_previousYear = this.secondAbsoluteDifferenceHeader;
                    }
                    if (this.forecast_previousYear_percent) {
                        this.secondRelativeDifferenceHeader = this.forecast_previousYear_percent;
                    }
                    else {
                        this.forecast_previousYear_percent = this.secondRelativeDifferenceHeader;
                    }
                }
            }
            else if (secondReferenceScenario === Scenario.Plan) {
                if (this.plan) {
                    this.secondReferenceHeader = this.plan;
                }
                else {
                    this.plan = this.secondReferenceHeader;
                }

                if (valueScenario === Scenario.Actual) {
                    if (this.actual_plan) {
                        this.secondAbsoluteDifferenceHeader = this.actual_plan;
                    }
                    else {
                        this.actual_plan = this.secondAbsoluteDifferenceHeader;
                    }
                    if (this.actual_plan_percent) {
                        this.secondRelativeDifferenceHeader = this.actual_plan_percent;
                    }
                    else {
                        this.actual_plan_percent = this.secondRelativeDifferenceHeader;
                    }
                }
                else if (valueScenario === Scenario.Forecast) {
                    if (this.forecast_plan) {
                        this.secondAbsoluteDifferenceHeader = this.forecast_plan;
                    }
                    else {
                        this.forecast_plan = this.secondAbsoluteDifferenceHeader;
                    }
                    if (this.forecast_plan_percent) {
                        this.secondRelativeDifferenceHeader = this.forecast_plan_percent;
                    }
                    else {
                        this.forecast_plan_percent = this.secondRelativeDifferenceHeader;
                    }
                }
            }
        }

        if (this.scenarioOptions.secondValueScenario !== null) {
            if (this.forecast) {
                this.secondValueHeader = this.forecast;
            }
            else {
                this.forecast = this.secondValueHeader;
            }
        }
    }

    public setTitleProperties(style: TitleStyle) {
        this.titleFontFamily = style.fontFamily;
        this.titleFontSize = style.fontSize;
        this.titleText = style.text;
        this.titleFontColor = style.fontColor;
        this.titleFontWeight = style.fontWeight;
        this.titleFontStyle = style.fontStyle;
        this.titleAlignment = style.alignment;
        this.titleWrap = style.wrapLongTitle;
    }

    public getRealInteractionSettingValue(settingValue: boolean): boolean {
        return true && (this.viewMode !== ViewMode.View || this.allowInteractions && settingValue);
    }

    public showNegativeValuesInParenthesis(): boolean {
        return this.negativeValuesFormat === NegativeValuesFormat.Parenthesis;
    }

    public setGlobalCategorySettings(globalCategorySettings: GlobalCategorySettings) {
        for (const [key, value] of Object.entries(globalCategorySettings)) {
            if (key === "fontSize") {
                this.categoriesFontSize = value;
            } else if (key === "displayOptions") {
                this.verticalCategoriesDisplay = value;
            } else if (key == "minWidth") {
                this.categoryMinWidth = value;
            } else {
                console.log(key, value);
                this[key] = value;
            }
        }
    }

    public setDataLabelSettings(dataLabelSettings: DataLabelSettings) {
        for (const [key, value] of Object.entries(dataLabelSettings)) {
            const settingsValue = value as any;
            if (key === "showDataLabels") {
                this[key] = settingsValue;
            } else if (key === "fontColor") {
                this.labelFontColor = settingsValue;
            } else if (key === "units") {
                this.displayUnits = settingsValue;
            } else if (key === "decimalPlaces") {
                this[key] = settingsValue;
            } else if (key === "decimalPlacesPercentage") {
                this[key] = settingsValue;
            } else if (key === "percentageInLabel") {
                this.showPercentageInLabel = settingsValue;
            } else if (key === "negativeValuesFormat") {
                this[key] = settingsValue;
            } else if (key === "labelDensity") {
                this[key] = settingsValue;
            } else if (key === "percentagePointUnit") {
                this.labelPercentagePointUnit = settingsValue;
            } else if (key === "fontSize") {
                this.labelFontSize = settingsValue;
            } else if (key === "fontFamily") {
                this.labelFontFamily = settingsValue;
            } else if (key === "labelBackgroundTransparency") {
                this[key] = settingsValue;
            } else {
                this[key] = value;
            }
        }
    }

    public setDifferenceHighlightSettings(differenceHighlightSettings: DifferenceHighlightSettings) {
        for (const [key, value] of Object.entries(differenceHighlightSettings)) {
            if (key === "differenceLabelType") {
                this.differenceLabel = <DifferenceLabel>value;
            } else {
                this[key] = value;
            }
        }
    }

    public setGlobalStackedChartSettings(globalStackedChartSettings: GlobalStackedChartSettings) {
        for (const [key, value] of Object.entries(globalStackedChartSettings)) {
            this[key] = value;
        }
    }

    public setGlobalLegendSettings(globalLegendSettings: GlobalLegendSettings) {
        for (const [key, value] of Object.entries(globalLegendSettings)) {
            this[key] = value;
        }
    }

    /**
     * Saves newly renamed legend entry to its appropriate legacy setting (or scenario)
     *
     * @param legendEntry is a setting key as used in capabilities file e.g "actual-previousYear"
     * @param value is new value entered by the user directly on legend entry on the visual container
     */
    public setLegendEntries(legendEntry: keyof ChartSettings, value: string) {
        this[this.mapLegendEntry(legendEntry)] = value;
    }

    /**
     * Casts key string used in ChartSettings into dashed version used in capabilities e.g. key_text -> key-text
     *
     * @param legendEntryScenario
     * @returns string
     */
    private mapLegendEntry(legendEntryScenario: keyof ChartSettings): string {
        return legendEntryScenario.split("_").join("-");
    }

    private isGroupInverted(group: string): boolean {
        return !!group && this.invertedGroups.indexOf(group) > -1;
    }

    public getInvert(group: string): boolean {
        const isGroupInverted = this.isGroupInverted(group);
        if (this.invert) {
            return !isGroupInverted;
        }
        else {
            return isGroupInverted;
        }
    }

    public isCategoryInverted(category: string): boolean {
        return !!category && this.invertedCategories.indexOf(category) > -1;
    }

    public isCategoryHighlighted(category: string): boolean {
        return !!category && this.highlightedCategories.length && this.highlightedCategories.indexOf(category) > -1;
    }

    public isGroupHighlighted(group: string): boolean {
        return !!group && this.highlightedGroups.length && this.highlightedGroups.indexOf(group) > -1;
    }

    public isCategoryResult(category: string): boolean {
        return this.resultCategories.indexOf(category) > -1;
    }

    public isCategoryFloatingResult(category: string): boolean {
        return this.floatingResultCategories.includes(category);
    }

    public shouldHideDataLabelUnits(): boolean {
        return this.displayUnits !== "Auto" && this.displayUnits !== "None" && this.showUnits !== DataLabelUnitOptions.DataLabels;
    }

    public shouldPlotVerticalCharts(): boolean {
        return this.showVerticalCharts && this.chartTypeSupportsVertical(this.chartType);
    }

    public chartTypeSupportsVertical(chartType: ChartType): boolean {
        return this.chartType === ChartType.Bar || this.chartType === ChartType.Variance
            || this.chartType === ChartType.Waterfall || this.chartType === ChartType.Pin || this.chartType === ChartType.Advert;
    }

    public getCategoryHighlightColor(category: string): string {
        const existingCustomCategoryColor = this.highlightedCategoriesCustomColors.find(c => c[category]);
        return existingCustomCategoryColor ? existingCustomCategoryColor[category] || this.colorScheme.highlightColor : this.colorScheme.highlightColor;
    }

    public getGroupHighlightColor(group: string): string {
        const existingCustomGroupColor = this.highlightedGroupsCustomColors.find(c => c[group]);
        return existingCustomGroupColor ? existingCustomGroupColor[group] || this.colorScheme.highlightColor : this.colorScheme.highlightColor;
    }

    public persistStackedChartChanges() {
        this.persistCallback?.(this);
    }

    public setDefaultLegendSettings() {
        this.useColoredLegendNames = false;
        this.useAliasesInTooltips = false;
        this.switchReferenceScenarios = false;
        this.showTopNChartsOptions = 0;
        this.topNChartsToKeep = 10;
        this.topNChartsPercentage = 80;
        this.legendItemsMargin = 0;
    }

    public persistCommentBoxSize(size: string) {
        this.commentBoxSize = size;
        this.persistCallback?.(this);
    }

    public persistCommentBoxVariance(commentBoxVariance: CommentBoxVariance) {
        this.commentBoxShowVariance = commentBoxVariance;
        this.persistCallback?.(this);
    }

    public persistCommentBoxVarianceIcon(commentBoxVarianceIcon: VarianceIcon) {
        this.commentBoxVarianceIcon = commentBoxVarianceIcon;
        this.persistCallback?.(this);
    }

    public persistCommentBoxExpandedGroupChange(group: string) {
        this.commentBoxExpandedGroupChange(group);
        this.persistCallback?.(this);
    }

    private commentBoxExpandedGroupChange(group: string) {
        if (this.isCommentBoxGroupExpanded(group)) {
            this.commentBoxExpandedGroup = "";
        }
        else {
            this.commentBoxExpandedGroup = group;
        }
    }

    public persistCommentBoxSettings() {
        this.persistCallback?.(this);
    }

    public isCommentBoxGroupExpanded(group: string): boolean {
        return !!group && this.commentBoxExpandedGroup === group;
    }

    public isCommentBoxVertical() {
        return this.commentBoxPlacement === CommentBoxPlacement.Left || this.commentBoxPlacement === CommentBoxPlacement.Right;
    }

    public shouldPlotStackedChart(isMultiples: boolean): boolean {
        return this.stackedChart && isMultiples;
    }

    public isChartDataToBeSorted(is2dMultiples: boolean): boolean {
        const hasMultiplesSort = this.multiplesSort !== Sort.None;
        const isLargestFirstLayout = this.multiplesLayoutType === LARGEST_FIRST_LAYOUT;
        const showTopNChartsOff = this.showTopNChartsOptions === ShowTopNChartsOptions.Off;
        const isStackedChart = this.stackedChart;
        const showTopNStackedOptionsOff = this.showTopNStackedOptions === ShowTopNChartsOptions.Off;
        const isStackedChartSortNone = this.stackedChartSort === Sort.None;

        return (
            !is2dMultiples
            && (hasMultiplesSort || isLargestFirstLayout)
            &&
            (
                (!isStackedChart && showTopNChartsOff)
                || (isStackedChart && showTopNStackedOptionsOff && !isStackedChartSortNone)
            )
        );
    }

    public persist(skipTimer = false) {
        const newSettings = { ... this };

        persistManagerInstance.update({
            objectName: CHART_SETTINGS_NAME, // IMPORTANT: make sure it's not named the same way the proxy is!!
            properties: JSON.parse(JSON.stringify(newSettings)),
        });

        if (skipTimer) {
            persistManagerInstance.flushQueue(); // override timer
        }
    }

    public setOrganizationStyleSettings(organizationStyleData: OrganizationStyleData) {
        // settings.colorScheme = { ...settings.colorScheme, ...importedSettings.colorScheme }; //possibly use this if we'd want to merge the color schemes
        this.chartStyle = ChartStyle.Company;
        this.selectedOrganizationStyleId = organizationStyleData.id;
        for (const [key, value] of Object.entries(organizationStyleData.styleJSON)) {
            const settingName = key.split("-").join("_");
            this[settingName] = value;
        }
    }
}
