import { DataView, DataViewMetadataColumn } from "@zebrabi/table-data";
import { ChartSettings } from "./settings/chartSettings";

import { mountChartChooser } from "@zebrabi/chart-chooser";
import { persistManagerInstance } from "@zebrabi/office-settings/PersistManager";
import { KNOWLEDGE_BASE_URL, TEMPLATES_URL } from "@zebrabi/licensing/constants";
import { getOfficeSettings } from "@zebrabi/office-settings";
import { looksLikeCommentHeader, MeasureRoles, tryGetMeasureRoleFromName } from "@zebrabi/data-helpers/fieldAssignment";
import { valuesTranslations } from "@zebrabi/data-helpers/translations";
import { getSampleDataRangeStartRowAndColumn, getExcelTable, isValidRangeInputData, checkForEmptyWorksheet } from '@zebrabi/data-helpers/excelDataHelpers';
import { DATA_SOURCE, DataSource, EMPTY_WORKSHEET_MSG, PARSING_ERROR_MSG, SHOW_CHOOSER_LINK_ID } from "@zebrabi/data-helpers/editData";

import { InsertType } from "@zebrabi/analytics-tracking/definitions";
import { trackUsedDataSource } from "@zebrabi/analytics-tracking/sourceDataTracking";
import { GlobalChartsState } from "./chartsGlobalState";

export class DataHelper {
    private dataChangeHandlerReference: OfficeExtension.EventHandlerResult<Excel.BindingDataChangedEventArgs>;
    private storedDataSource: DataSource;

    private getVisualDataView(): DataView {
        return GlobalChartsState.dataView;
    }

    private getVisualSettings(): ChartSettings {
        return GlobalChartsState.settings;
    }

    constructor(private dataChangeCallback: (updateSettings?: ChartSettings) => Promise<void>) {
    }

    public async getDataView(): Promise<DataView> {
        if (Office.context.host === Office.HostType.PowerPoint) {
            return this.getVisualDataView(); // This is updated in visual.ts subscribeToXSpreadSheetData
        }
        if (Office.context.host === Office.HostType.Excel) {
            this.storedDataSource = this.getDataSource();
            if (!this.storedDataSource) {
                //check for empty sheet
                const isEmpty = await checkForEmptyWorksheet();
                if (isEmpty) {
                    mountChartChooser();
                    return this.getEmptyDataView(this.getDataParsingErrorMsgHtml(true));
                }
            }
            const dataVIew = this.readExcelData("");
            return dataVIew;
        }
        throw new Error("Unsupported Office app");
    }

    public async writeDataViewToWorksheet() {
        await Excel.run(async (context) => {
            const activeWorksheet = context.workbook.worksheets.getActiveWorksheet();
            const dataView = this.getVisualDataView();
            if (!dataView) {
                return;
            }
            let data = [];
            data.push(["Category", ...dataView.valueFields]);
            dataView.categories[0].values.forEach((c, i) => {
                const rowData = [c];
                dataView.valueFields.forEach((v, j) => {
                    rowData.push(dataView.values[j].values[i]);
                });
                data.push(rowData);
            });

            const { startRow, startColumn } = await getSampleDataRangeStartRowAndColumn(activeWorksheet, context, data.length);

            const range = activeWorksheet.getRangeByIndexes(startRow, startColumn, dataView.categories[0].values.length + 1, dataView.valueFields.length + 1);
            range.values = data;
            range.select();
            const table = activeWorksheet.tables.add(range, true);

            activeWorksheet.load({ id: true });
            table.load({ id: true, name: true });

            await context.sync();

            this.saveDataSource({ table: table.name, tableId: table.id, worksheetId: activeWorksheet.id });
        }).catch((error) => {
            console.log("Excel.Run error: " + error);
            if (error instanceof OfficeExtension.Error) {
                console.log("Debug info: " + JSON.stringify(error.debugInfo));
            }
        });
    }

    public async readExcelData(name: string): Promise<DataView> {
        return Excel.run(async (context) => {
            let activeWorksheet = context.workbook.worksheets.getActiveWorksheet();
            activeWorksheet.load({ id: true, name: true });
            await context.sync();

            // check for saved data source
            const dataSource = this.storedDataSource;
            if (dataSource) {
                // create existing add-in from saved data source
                if (dataSource.pivotTable) {
                    // check if worksheet.pivotTables would be better to use instead
                    const storedPivotTable = context.workbook.pivotTables.getItemOrNullObject(dataSource.pivotTable);
                    const ws = dataSource.worksheetId ? context.workbook.worksheets.getItemOrNullObject(dataSource.worksheetId) : null;
                    await context.sync();
                    if (!storedPivotTable.isNullObject) {
                        //Hack: it looks like that for the multi level row hierarcihies pivot table has to be read from worksheet and not workbook
                        if (ws && !ws.isNullObject) {
                            const wsPivotTable = ws.pivotTables.getItemOrNullObject(dataSource.pivotTable);
                            await context.sync();
                            if (!wsPivotTable.isNullObject) {
                                return await this.getPivotTableDataView(wsPivotTable, context, ws, false);
                            }
                        }
                        return await this.getPivotTableDataView(storedPivotTable, context, activeWorksheet, false);
                    }
                    return this.getEmptyDataView(this.getDataParsingErrorMsgHtml() + " Error details: pivot table used to create this add-in not found: " + dataSource.pivotTable);
                }

                // check if we should use active worksheet for reading data, desktop versions loads add-ins differenty then online so using active worksheet is NOT always correct
                if (dataSource.worksheetId && dataSource.worksheetId !== activeWorksheet.id) {
                    const dataSourceWs = context.workbook.worksheets.getItemOrNullObject(dataSource.worksheetId);
                    await context.sync();
                    if (!dataSourceWs.isNullObject) {
                        activeWorksheet = dataSourceWs;
                    }
                }
                else if (!dataSource.worksheetId && dataSource.range) {
                    const storedWsName = dataSource.range.split("!", 2)[0];
                    if (dataSource.range && activeWorksheet.name !== storedWsName) {
                        activeWorksheet = context.workbook.worksheets.getItemOrNullObject(storedWsName);
                    }
                }
                const sourceDataRange = await this.getStoredDataSourceRange(context, activeWorksheet, dataSource);
                if (sourceDataRange) {
                    const rangeDataView = await this.getDataViewFromRange(context, sourceDataRange);
                    return rangeDataView ? rangeDataView : this.getEmptyDataView(this.getDataParsingErrorMsgHtml() + " Error details: error reading data from saved data source " + JSON.stringify(dataSource));
                }
                return this.getEmptyDataView(this.getDataParsingErrorMsgHtml() + " Error details: could not parse " + JSON.stringify(dataSource));
            }

            // inserting a new add-in
            const selectedRange = await this.getSelectedRange(context);

            if (selectedRange === null) {
                return await this.getEmptySelectionDataView(context, activeWorksheet, null);
            }
            else {
                const pivotTables = selectedRange.getPivotTables();
                const pivotTablesCount = pivotTables.getCount();
                await context.sync();
                if (pivotTablesCount.value > 0) {
                    return await this.getPivotTableDataView(pivotTables.getFirst(), context, activeWorksheet, true);
                }

                const tables = selectedRange.getTables();
                const tablesCount = tables.getCount();
                await context.sync();
                if (tablesCount.value > 0) {
                    const table = tables.getFirst();
                    return await this.getExcelTableDataView(table, context, activeWorksheet, true);
                }

                // insert from suitable Range
                selectedRange.load({ values: true });
                await context.sync();
                const isEmptyCellSelected = selectedRange.values[0][0] === "" && selectedRange.cellCount === 1;

                if (isEmptyCellSelected) {
                    return await this.getEmptySelectionDataView(context, activeWorksheet, selectedRange);
                }
                else {
                    if (selectedRange.cellCount < 6) {
                        // try get surrounding range
                        const surroundingRange = await this.getSurroundingNonBlankRange(context, activeWorksheet, selectedRange);
                        if (surroundingRange) {
                            let rangeDataView = await this.getDataViewFromRange(context, surroundingRange);
                            if (rangeDataView) {
                                this.saveDataSource({ range: surroundingRange.address, worksheetId: activeWorksheet.id });
                                this.trackDataSource(context, InsertType.Range, rangeDataView, surroundingRange);
                                return rangeDataView;
                            }
                        }
                    }
                    else {
                        // use selected range
                        const rangeDataView = await this.getDataViewFromRange(context, selectedRange);
                        if (rangeDataView) {
                            this.saveDataSource({ range: selectedRange.address, worksheetId: activeWorksheet.id });
                            this.trackDataSource(context, InsertType.Range, rangeDataView, selectedRange);
                            return rangeDataView;
                        }
                    }

                    this.trackDataSource(context, InsertType.Range, null, selectedRange);

                    return this.getEmptyDataView(this.getDataParsingErrorMsgHtml() + " Error details: error reading data from selected range");
                }
            }
        }).catch((error) => {
            console.log("readData Excel.Run error: " + error);
            if (error instanceof OfficeExtension.Error) {
                console.log("Debug info: " + JSON.stringify(error.debugInfo));
            }
            return null;
        });
    }

    private async checkForValidFieldsConfiguration(pivotTable: Excel.PivotTable, dataView: DataView): Promise<boolean> {
        const rowHierarcyhiesCount = pivotTable.rowHierarchies.getCount();
        const colHierarcyhiesCount = pivotTable.columnHierarchies.getCount();
        const dataHierarcyhiesCount = pivotTable.dataHierarchies.getCount();

        await pivotTable.context.sync()
        let isValid = true;
        if (rowHierarcyhiesCount.value === 0 || dataHierarcyhiesCount.value === 0) {
            dataView.errorMessage = "Not enough fields. Please have at least one field in rows and one in values.";
            isValid = false;
        }
        else if (rowHierarcyhiesCount.value > 1) {
            dataView.errorMessage = "Too many fields. Please have exactly one field in rows.";
            isValid = false;
        }
        else if (colHierarcyhiesCount.value > 2) {  //empty columns has one hidden columns
            dataView.errorMessage = "Too many fields. Please have no more than one field in columns.";
            isValid = false;
        }

        return isValid;
    }

    /* https://docs.microsoft.com/en-us/office/dev/add-ins/excel/excel-add-ins-pivottables#pivottable-layouts-and-getting-pivoted-data */
    // eslint-disable-next-line max-lines-per-function
    private async getPivotTableDataView(pivotTable: Excel.PivotTable, context: Excel.RequestContext, activeWorksheet: Excel.Worksheet, saveToSettings: boolean) {

        let dataView = this.getEmptyDataView("");
        try {
            const bodyFullRange = pivotTable.layout.getRange();

            this.addDataChangeHandler(context, bodyFullRange, Excel.BindingType.range, "pivotTableDataChangeEvent");

            const isValidConfiguration = await this.checkForValidFieldsConfiguration(pivotTable, dataView);
            if (!isValidConfiguration) {
                return dataView;
            }

            //Change pivot table layout to tabular.
            //// this code block is commented out because loading a layout type sometimes crashes the Excel randomly, we should be able to re-use it (if needed) when this bug is fixed by MSFT
            // pivotTable.layout.load("layoutType");
            // await context.sync();
            // //console.log("pivotTable.layout.layoutType", pivotTable.layout.layoutType)
            // if (pivotTable.layout.layoutType !== "Tabular") {
            //     dataView.errorMessage = "An error occured while reading pivot table data. Please use 'Tabular' pivot table layout if possible."
            //     pivotTable.layout.layoutType = "Tabular";
            //     await context.sync();
            // }

            const rowHierarchies = pivotTable.rowHierarchies;
            const columnHierarchies = pivotTable.columnHierarchies;
            const dataHierarchies = pivotTable.dataHierarchies;

            pivotTable.load({ name: true });
            pivotTable.layout.load({ showRowGrandTotals: true, showColumnGrandTotals: true }); // subtotalLocation

            rowHierarchies.load({ id: true, name: true });
            columnHierarchies.load({ id: true, name: true });
            dataHierarchies.load({ id: true, name: true }); // items/numberFormat, items/position, items/showAs

            await context.sync();

            const valuesCol = columnHierarchies.items.find(item => valuesTranslations.includes(item.id));
            let columnNames = columnHierarchies.items.filter(item => item.id !== valuesCol?.id).map(c => c.name); // "Values" column is filtered out since it is not used as Grouping column

            let rowNames = rowHierarchies.items.map(item => item.name);
            let valueNames = dataHierarchies.items.map(item => item.name);

            dataView = {
                values: [],
                categories: [],
                rowFields: rowNames,
                columnFields: columnNames,
                valueFields: valueNames,
                rowGrandTotal: pivotTable.layout.showRowGrandTotals,    // adds grand total columns
                columnGrandTotal: pivotTable.layout.showColumnGrandTotals,  // adds grand total row
                rowNames: rowNames,
                columnNames: columnNames,
                valueNames: valueNames,
                metadata: { columns: [] }
            };

            this.getMetadata(dataView);

            if (dataView.valueFields.length < 1 || dataView.rowFields.length < 1 || dataView.rowFields.length > 1) {
                dataView.errorMessage = "Invalid data fields configuration or empty data";
                return dataView;
            }

            const dataBodyRange = pivotTable.layout.getDataBodyRange();
            const rowLabelsRange = pivotTable.layout.getRowLabelRange();
            const columnLabelsRange = pivotTable.layout.getColumnLabelRange();
            dataBodyRange.load({ values: true });
            rowLabelsRange.load({ values: true });
            columnLabelsRange.load({ values: true });

            rowLabelsRange.load({ columnCount: true, numberFormat: true, numberFormatCategories: true, rowCount: true, valueTypes: true });

            await context.sync();
            // Get cells (data) of different parts of pivot table.
            let dataValues = dataBodyRange.values;
            let rowLabelsValues = rowLabelsRange?.values || [];
            let columnLabelsValues = columnLabelsRange.values;

            this.checkRowsLabelsDataTypeForDates(rowLabelsRange, rowLabelsValues);

            if (dataView.valueFields.length === 1) {
                if (dataView.columnFields.length === 0) {
                    // 1 measure
                    dataView = this.parseSingleMeasureDataView(dataView, rowLabelsValues, columnLabelsValues, dataValues);
                } else if (dataView.columnFields.length === 1) {
                    // multiples 1
                    dataView = this.parseSingleMeasureDataViewWithGroups(dataView, rowLabelsValues, columnLabelsValues, dataValues);
                }
            }
            else {
                if (dataView.columnFields.length === 1) {
                    dataView = this.parseMultiMeasureDataViewWithGroups(dataView, rowLabelsValues, columnLabelsValues, dataValues);
                }
                else {
                    dataView = this.parseMultiMeasureDataView(dataView, rowLabelsValues, columnLabelsValues, dataValues);
                }
            }

            // remove grand total row (column grand total on the Excel UI)
            if (!dataView.errorMessage && dataView.columnGrandTotal && dataView.categories.length) {
                dataView.categories[0].values.pop();
                dataView.values.forEach(v => v.values.pop());
            }

            if (saveToSettings) {
                this.saveDataSource({ pivotTable: pivotTable.name, worksheetId: activeWorksheet.id });
                this.trackDataSource(context, InsertType.PivotTable, dataView, dataBodyRange);
            }
        }
        catch (error) {
            console.log(error)
            dataView.errorMessage = "pivot table data could not be parsed correctly";
        }

        return dataView;
    }

    private addDataChangeHandler(context: Excel.RequestContext, range: Excel.Range, bindingType: Excel.BindingType, id: string) {
        const binding = context.workbook.bindings.add(range, bindingType, id);
        if (!this.dataChangeHandlerReference) {
            this.dataChangeHandlerReference = binding.onDataChanged.add(this.dataChangedEventHandler.bind(this));
        }
    }

    private getDataSource(): DataSource {
        return <DataSource>getOfficeSettings(DATA_SOURCE);
    }

    private saveDataSource(dataSource: DataSource) {
        Office.context.document.settings.set(DATA_SOURCE, dataSource);
        persistManagerInstance.update({
            objectName: DATA_SOURCE,
            properties: dataSource
        });
    }

    private async trackDataSource(context: Excel.RequestContext, insertType: InsertType, dataView: DataView, range: Excel.Range) {
        try {
            range.load({ values: true });
            await context.sync();
            //const sourceDataJson = range.valuesAsJsonLocal;
            const sourceData = range.values;
            trackUsedDataSource(insertType, sourceData);            
        } catch (error) {
            console.error("data source used err", error);
        }
    }

    private checkRowsLabelsDataTypeForDates(rowLabelsRange: Excel.Range, rowLabelsValues: any[][]) {
        for (let i = 0; i < rowLabelsRange.rowCount; i++) {
            for (let j = 0; j < rowLabelsRange.columnCount; j++) {
                const valueType = rowLabelsRange.valueTypes[i][j];
                const numberFormatCategory = rowLabelsRange.numberFormatCategories[i][j];
                if ((valueType === Excel.RangeValueType.double || valueType === Excel.RangeValueType.integer)
                    && (numberFormatCategory === Excel.NumberFormatCategory.custom || numberFormatCategory === Excel.NumberFormatCategory.date)) {
                    const val = rowLabelsValues[i][j];
                    rowLabelsValues[i][j] = new Date(Date.UTC(0, 0, val - 1)).toLocaleDateString();
                }
            }
        }
    }

    private getEmptyDataView(msg: string): DataView {
        return {
            values: [],
            categories: [],
            rowFields: null,
            columnFields: null,
            valueFields: null,
            rowGrandTotal: null,
            columnGrandTotal: null,
            errorMessage: msg,
            metadata: null,
            rowNames: null,
            columnNames: null,
            valueNames: null,
        };
    }

    private async getEmptySelectionDataView(context: Excel.RequestContext, activeWorksheet: Excel.Worksheet, selectedRange: Excel.Range): Promise<DataView> {
        const tableCount = activeWorksheet.tables.getCount();
        const pivotTableCount = activeWorksheet.pivotTables.getCount();
        const pivotTablesCollection = activeWorksheet.pivotTables.load({ id: true, name: true });
        const tableCollection = activeWorksheet.tables;
        await context.sync();

        if (pivotTableCount.value > 0) {
            const pivotTable = pivotTablesCollection.items[0];
            return await this.getPivotTableDataView(pivotTable, context, activeWorksheet, true);
        }
        else if (tableCount.value > 0) {
            const table = tableCollection.getItemAt(0);
            return await this.getExcelTableDataView(table, context, activeWorksheet, true);
        }
        else {
            if (selectedRange) {
                const surroundingRange = await this.getSurroundingNonBlankRange(context, activeWorksheet, selectedRange);
                if (surroundingRange) {
                    let rangeDataView = await this.getDataViewFromRange(context, surroundingRange);
                    if (rangeDataView) {
                        this.saveDataSource({ range: surroundingRange.address, worksheetId: activeWorksheet.id });
                        this.trackDataSource(context, InsertType.Range, rangeDataView, surroundingRange);
                        return rangeDataView;
                    }
                }
            }

            // try to use used range or show chooser
            const usedRange = activeWorksheet.getUsedRangeOrNullObject(true);
            await context.sync();
            if (!usedRange.isNullObject) {
                const rangeDataView = await this.getDataViewFromRange(context, usedRange);
                if (rangeDataView) {
                    this.saveDataSource({ range: usedRange.address, worksheetId: activeWorksheet.id });
                    this.trackDataSource(context, InsertType.Range, rangeDataView, usedRange);
                    return rangeDataView;
                }
            }

            mountChartChooser();

            this.trackDataSource(context, InsertType.Range, null, selectedRange);

            return this.getEmptyDataView(this.getDataParsingErrorMsgHtml(true) + " Error details: no suitable data source found.");
        }
    }

    private async getExcelTableDataView(table: Excel.Table, context: Excel.RequestContext, activeWorksheet: Excel.Worksheet, saveToSettings: boolean): Promise<DataView> {
        table.load({ id: true, name: true });
        await context.sync();

        const rangeDataView = await this.getDataViewFromRange(context, table.getRange());
        if (rangeDataView && saveToSettings) {
            this.saveDataSource({ table: table.name, tableId: table.id, worksheetId: activeWorksheet.id });
            this.trackDataSource(context, InsertType.Table, rangeDataView, table.getRange());
            return rangeDataView;
        }

        this.trackDataSource(context, InsertType.Table, null, table.getRange());

        return this.getEmptyDataView(this.getDataParsingErrorMsgHtml() + " Error details: could not parse Excel Table " + table.name);
    }

    private async getStoredDataSourceRange(context: Excel.RequestContext, activeWorksheet: Excel.Worksheet, dataSource: DataSource): Promise<Excel.Range> {
        let range: Excel.Range = null;
        try {
            if (dataSource && dataSource.table) {
                range = (await getExcelTable(context, activeWorksheet, dataSource.table, dataSource.tableId)).getRange();
                await context.sync();
            }
            else if (dataSource && dataSource.range) {
                // read data from stored range
                const savedSourceAddress: string = dataSource.range.toString();
                const savedWsRange = savedSourceAddress.split("!", 2);
                // use only range address without worksheet name
                const sourceRange = savedWsRange.length === 2 ? savedWsRange[1] : dataSource.range;
                // read data from stored range
                range = activeWorksheet.getRange(sourceRange);
                await context.sync();
            }

            return range;
        }
        catch (error) {
            console.log(error);
            return null;
        }
    }

    private async getSelectedRange(context: Excel.RequestContext): Promise<Excel.Range> {
        let selectedRange: Excel.Range = null;
        try {
            selectedRange = context.workbook.getSelectedRange();
            selectedRange.load({ address: true, cellCount: true });
            //TODO: check if selectedRange.worksheet === activeWorksheet ?
            await context.sync();
        }
        catch (e) {
            console.log("error parsing Selected range:", e);
            selectedRange = null;
        }
        return selectedRange;
    }

    private async getSurroundingNonBlankRange(context: Excel.RequestContext, activeWorksheet: Excel.Worksheet, selectedCell: Excel.Range): Promise<Excel.Range> {
        try {
            const usedRange = activeWorksheet.getUsedRangeOrNullObject(true)
            await context.sync();
            if (usedRange.isNullObject) {
                return null;
            }

            const range = selectedCell.getSurroundingRegion();
            range.load({ address: true });
            await context.sync();
            range.select();
            return range;
        }
        catch (e) {
            console.log("error parsing Selected range:", e);
            return null;
        }
    }

    private async getDataViewFromRange(context: Excel.RequestContext, sourceRange: Excel.Range): Promise<DataView> {
        try {
            const range = sourceRange;
            range.load({
                address: true,
                cellCount: true,
                columnCount: true,
                rowCount: true,
                text: true,
                values: true,
                valueTypes: true
            });
            await context.sync();

            const firstCol = range.getColumn(0);
            firstCol.load({
                columnCount: true,
                rowCount: true,
                values: true,
                valueTypes: true,
                numberFormatCategories: true
            });

            const firstRow = range.getRow(0);
            firstRow.load({ values: true });

            await context.sync();

            const rowLabelsValues = firstCol.values;
            this.checkRowsLabelsDataTypeForDates(firstCol, rowLabelsValues);

            const columnLabelsValues = firstRow.values[0];
            rowLabelsValues.shift();
            columnLabelsValues.shift();

            const rowFields = [];
            let columnFields = [];
            const rowNames = [];
            let columnNames = [];
            const valueNames = [];

            let valueFields = columnLabelsValues;
            let measureCount = columnLabelsValues.length;

            if (columnLabelsValues.some(c => looksLikeCommentHeader(c)) || this.hasCommentsDataCol(range.valueTypes)) {
                measureCount--;
            }
            let isMultiplesData = false;
            if (measureCount > 4) { // parse small multiples
                valueFields = ["Values"];
                columnNames = ["Group"];
                columnFields = ["Group"];
                isMultiplesData = true;
            }

            let dataView: DataView = {
                values: [],
                categories: [],
                rowFields: rowFields,
                columnFields: columnFields,
                valueFields: <any>valueFields,
                rowGrandTotal: false,
                columnGrandTotal: false,
                rowNames: rowNames,
                columnNames: columnNames,
                valueNames: valueFields,
                metadata: { columns: [] }
            };

            if (!isValidRangeInputData(range)) {
                dataView.errorMessage = this.getDataParsingErrorMsgHtml();
                return dataView;
            }

            this.getMetadata(dataView);

            if (isMultiplesData) {
                dataView = this.parseRangeMultiplesDataView(dataView, rowLabelsValues, columnLabelsValues, range)
            }
            else {
                dataView = this.parseRangeDataView(dataView, rowLabelsValues, columnLabelsValues, range.values, range);
            }

            this.addDataChangeHandler(context, range, Excel.BindingType.range, "chartsRangeChangeEvent"); // check table BindingType for tables

            return dataView;

        } catch (error) {
            console.log("range parsing error");
            console.error(error);
        }
    }

    hasCommentsDataCol(valueTypes: Excel.RangeValueType[][]): boolean {
        if (!valueTypes || valueTypes[0]?.length < 2) {
            return false;
        }

        let hasCommentsCol = false;
        // iterate 2. to last col
        for (let j = 1; j < valueTypes[0].length; j++) {
            const colValueTypes = valueTypes.map(t => t[j]);
            colValueTypes.shift();
            let isTextCol = colValueTypes.every(t => t === Excel.RangeValueType.error || t === Excel.RangeValueType.empty || t === Excel.RangeValueType.string);
            if (isTextCol) {
                hasCommentsCol = true;
                break;
            }
        }
        return hasCommentsCol;
    }

    getMetadata(dv: DataView): void {
        // todo?
        // dv.rowFields.forEach((rf) => {
        //     dv.metadata.columns.push({
        //         displayName: rf.name,
        //         roles: { "Category": true },
        //         type: { "text": true },
        //         queryName: rf.name,
        //     });
        // });
        // dv.columnFields.forEach(cf => {
        //     dv.metadata.columns.push({
        //         displayName: cf.name,
        //         roles: { "Group": true },
        //         type: { "text": true },
        //         queryName: cf.name,
        //     });
        // });

        let availableRoles = [MeasureRoles.Values, MeasureRoles.PreviousYear, MeasureRoles.Plan, MeasureRoles.Forecast, MeasureRoles.Comments];
        let usedRoles: MeasureRoles[] = [];
        let usedIndexes: number[] = [];
        let metaValueColumns: DataViewMetadataColumn[] = [];

        // auto assign measure roles
        dv.valueFields.forEach((vf, ix) => {
            const measureName = dv.valueNames[ix];
            const measureRole = tryGetMeasureRoleFromName(measureName);
            if (measureRole && !usedRoles.includes(measureRole)) {
                metaValueColumns.push(this.getMetaDataColumn(measureName, measureRole, ix));
                usedRoles.push(measureRole);
                availableRoles = availableRoles.filter(role => role != measureRole);
                usedIndexes.push(ix);
            }
        });

        // assing remaining measures
        dv.valueFields.forEach((field, index) => {
            if (!usedIndexes.includes(index)) {
                const measureName = dv.valueNames[index];
                let role = availableRoles.shift();
                if (!role) {
                    role = MeasureRoles.Values;
                }
                metaValueColumns.push(this.getMetaDataColumn(measureName, role, index));
                usedRoles.push(role);
                usedIndexes.push(index);
            }
        });

        // sort cols by index
        metaValueColumns.sort((c1, c2) => c1.index - c2.index);
        usedRoles.sort((r1, r2) => metaValueColumns.findIndex(c => c.roles[r1]) - metaValueColumns.findIndex(c => c.roles[r2]));

        dv.metadata.columns = metaValueColumns;
        dv.metadata.measureRoles = usedRoles;
    }

    getMetaDataColumn(measureName: string, measureRole: MeasureRoles, index: number): DataViewMetadataColumn {
        return {
            displayName: measureName,
            roles: {
                [measureRole]: true
            },
            type: measureRole === MeasureRoles.Comments ? { "text": true } : { "numeric": true },
            queryName: measureName,
            index: index
        };
    }

    parseMultiMeasureDataViewWithGroups(dataView: DataView, rowLabelsValues: any[][], columnLabelsValues: any[][], dataValues: any[][]): DataView {
        dataView.categories = [{
            values: rowLabelsValues.map(rlv => rlv[0]),
            source: { displayName: dataView.rowNames[0] }
        }];

        const columnLabelIndex = 1; // may need reordeing on Column fields on the Excel UI (possibly handling this could be improved)
        let groupNames = columnLabelsValues[columnLabelIndex].filter(name => name !== "");

        if (dataView.rowGrandTotal) {    // if grand total On, remove total column labels for other measures
            groupNames = groupNames.slice(0, 1 - dataView.valueNames.length);
        }

        dataView.values = dataValues[0].map((dv, index) => {
            return {
                values: dataValues.map(dv => this.isValidNonNullNumber(dv[index]) ? dv[index] : null),
                source: {
                    displayName: dataView.valueNames[index % dataView.valueNames.length],
                    groupName: groupNames[~~(index / dataView.valueNames.length)]
                }
            };
        });

        dataView.values.grouped = function () {
            return groupNames//.slice(0, -1)
                .map(groupname => {
                    return {
                        name: groupname,
                        values: dataView.values.filter(v => v.source.groupName === groupname)
                    };
                });
        };

        return dataView;
    }

    parseMultiMeasureDataView(dataView: DataView, rowLabelsValues: any[][], columnLabelsValues: any[][], dataValues: any[][]): DataView {
        dataView.categories = [{
            values: rowLabelsValues.map(rlv => rlv[0]),
            source: { displayName: dataView.rowNames[0] }
        }];

        dataView.values = dataValues[0].map((dv, index) => {
            return {
                values: dataValues.map(dv => this.isValidNonNullNumber(dv[index]) ? dv[index] : null),
                source: {
                    displayName: dataView.valueNames[index]
                }
            };
        });

        dataView.values.grouped = function () {
            return [{ values: dataView.values }];
        };
        return dataView;
    }

    parseRangeDataView(dataView: DataView, rowLabelsValues: any[][], columnLabelsValues: any[][], dataValues: any[][], range: Excel.Range): DataView {

        dataView.categories = [{ values: rowLabelsValues.map(rlv => rlv[0]), source: { displayName: dataValues[0][0] || "Category" } }];
        dataView.values = [];
        const rangeValueTypes = range.valueTypes;

        for (let j = 1; j < range.columnCount; j++) {
            let values = dataValues.map((dv, i) => dv[j]);
            const valueTypes = rangeValueTypes.map(t => t[j]);
            values.shift(); // remove header (1. row)
            valueTypes.shift();
            values = values.map((v, i) => valueTypes[i] !== Excel.RangeValueType.error ? v : null);

            dataView.values.push({
                source: {
                    displayName: dataValues[0][j],
                },
                values: values
            });
        }

        dataView.values.grouped = function () {
            return [{ values: dataView.values }];
        };
        return dataView;
    }

    parseRangeMultiplesDataView(dataView: DataView, rowLabelsValues: any[][], columnLabelsValues: any[][], range: Excel.Range): DataView {
        const dataValues = range.values;

        dataView.categories = [{
            values: rowLabelsValues.map(rlv => rlv[0]),
            source: { displayName: dataValues[0][0] || "Category" }
        }];

        dataView.values = [];
        const rangeValueTypes = range.valueTypes;
        for (let j = 1; j < range.columnCount; j++) {
            let values = dataValues.map((dv, i) => dv[j]);
            const valueTypes = rangeValueTypes.map(t => t[j]);
            values.shift(); // remove header (1. row)
            valueTypes.shift();
            values = values.map((v, i) => valueTypes[i] !== Excel.RangeValueType.error ? v : null);

            dataView.values.push({
                source: {
                    displayName: "Values", //dataValues[0][j],
                    groupName: columnLabelsValues[j - 1]
                },
                values: values
            });
        }

        dataView.values.grouped = function () {
            return dataView.values.map(vals => {
                return {
                    name: vals.source.groupName,
                    values: [vals]
                };
            });
        };

        return dataView;
    }

    parseSingleMeasureDataViewWithGroups(dataView: DataView, rowLabelsValues: any[][], columnLabelsValues: any[][], dataValues: any[][]): DataView {

        dataView.categories = [{ values: rowLabelsValues.map(rlv => rlv[0]), source: { displayName: dataView.rowNames[0] } }];
        dataView.values = dataValues[0].map((dv, index) => {
            return {
                values: dataValues.map(dv => this.isValidNonNullNumber(dv[index]) ? dv[index] : null),
                source: {
                    displayName: dataView.valueNames[0],
                    groupName: columnLabelsValues[1][index]
                }
            };
        });

        dataView.values.grouped = function () {
            return dataView.values.map(vals => {
                return {
                    name: vals.source.groupName,
                    values: [vals]
                };
            });
        };

        return dataView;
    }

    parseSingleMeasureDataView(dataView: DataView, rowLabelsValues: any[][], columnLabelsValues: any[][], dataValues: any[][]): DataView {

        dataView.categories = [{ values: rowLabelsValues.map(rlv => rlv[0]), source: { displayName: dataView.rowNames[0] } }];
        dataView.values = [{ values: dataValues.map(dv => this.isValidNonNullNumber(dv[0]) ? dv[0] : null), source: { displayName: dataView.valueNames[0] } }];
        dataView.values.grouped = function () {
            return [{ values: dataView.values }];
        };

        return dataView;
    }

    /* turns a 2x8 array into an 8x2 */
    public transposeArray(source: any[][]): Array<[][]> {
        return source[0].map((_, c) => source.map(r => r[c]));
    }

    private async dataChangedEventHandler(event: Excel.BindingDataChangedEventArgs) {
        await Excel.run(async (context) => {
            //context.runtime.load("enableEvents");
            await context.sync();
            if (this.dataChangeHandlerReference) {
                this.dataChangeHandlerReference.remove();
                await this.dataChangeHandlerReference.context.sync();
                this.dataChangeHandlerReference = null;
            }

            const binding = context.workbook.bindings.getItemOrNullObject(event.binding.id);
            await context.sync();
            if (!binding.isNullObject) {
                // update stored range if it has changed, this handles inserts/deletes of Excel rows/columns
                this.storedDataSource = this.getDataSource();
                if (this.storedDataSource?.range) {
                    const bindingRange = binding.getRange();
                    bindingRange.load({ address: true });
                    await context.sync();
                    const boundRangeAddress = bindingRange.address;

                    if (boundRangeAddress !== this.storedDataSource.range) {
                        this.storedDataSource.range = boundRangeAddress;
                        this.saveDataSource(this.storedDataSource);
                    }
                }
                binding.delete();
            }

            await context.sync();
            this.dataChangeCallback(this.getVisualSettings());

            return context.sync();
        }).catch((error) => {
            console.log("dataChangedEvent error: " + error);
            if (error instanceof OfficeExtension.Error) {
                console.log('Debug info: ' + JSON.stringify(error.debugInfo));
            }
        });
    }

    private getDataParsingErrorMsgHtml(isEmptyWorksheet: boolean = false): string {
        let msg = isEmptyWorksheet ? EMPTY_WORKSHEET_MSG : PARSING_ERROR_MSG;
        if (isEmptyWorksheet) {
            msg += "<br> If it does not, please try to <a href='#' onclick='window.location.reload();'>reload your add-in.</a><br>"
        } else {
            // add an option to open charts chooser
            msg += `<br> Alternatively, you can insert a visual with sample data from the <a id='${SHOW_CHOOSER_LINK_ID}' href='#'>Charts gallery</a><br>`;
        }
        const kbUrl = KNOWLEDGE_BASE_URL.replace("$host", Office.context.host === Office.HostType.Excel ? "excel" : "powerpoint").replace("$visual", "charts");
        const templateUrl = TEMPLATES_URL.replace("$host", Office.context.host === Office.HostType.Excel ? "excel" : "powerpoint").replace("$visual", "charts");
        return `<p>${msg} For more help on data preparation visit our <a href='${kbUrl}' target='_blank' id='error-msg-kb-url'>knowledge base</a>  or download a <a href='${templateUrl}' target='_blank' id='error-msg-template-url'>template file</a>.</p>`;
        // https://zebrabi.com/templates/
        // https://help.zebrabi.com/excel/preparing-data/
    }

    private isValidNonNullNumber(value: any) {
        return value !== null && value !== ""; // check this for pivot tables/comments //&& typeof value === "number"; // Number(value) // //!isNaN(value)
    }
}
