import { Categories } from "../category.type";

import VarianceStructureBarImage from "./images/variance-structure-bar.svg";
import VarianceStructureDotImage from "./images/variance-structure-dot.svg";
import VarianceStructureColumnImage from "./images/variance-time-column.svg";
import VarianceTimeAreaImage from "./images/variance-time-area.svg";
import VarianceTimeDotImage from "./images/variance-time-dot.svg";
import IntegratedVarianceStructureBarImage from "./images/integrated-variance-structure-bar.svg";
import IntegratedVarianceTimeColumnImage from "./images/integrated-variance-time-column.svg";
import IntegratedVarianceTimeColumnResponsiveImage from "./images/integrated-variance-time-column-responsive.svg";
import IntegratedVarianceTimeAreaImage from "./images/integrated-variance-time-area.svg";
import VarianceHorizontalSmallMultiplesImage from "./images/variance-horizontal-small-multiples.svg";
import VarianceIntegratedSmallMultiplesImage from "./images/variance-integrated-small-multiples.svg";

import {
  IntegratedVarianceStructureBarCommentData,
  IntegratedVarianceStructureBarSampleData,
  IntegratedVarianceTimeAreaCommentData,
  IntegratedVarianceTimeAreaSampleData,
  IntegratedVarianceTimeColumnCommentData,
  IntegratedVarianceTimeColumnResponsiveCommentData,
  IntegratedVarianceTimeColumnResponsiveSampleData,
  IntegratedVarianceTimeColumnSampleData,
  VarianceStructureColumnCommentData,
  VarianceStructureColumnSampleData,
  VarianceTimeDotCommentData,
  VarianceTimeDotSampleData,
} from "./varianceCategories.mock";
import { ChartType } from "@zebrabi/charts";
import { ABSOLUTE, INTEGRATED, RELATIVE, RESPONSIVE } from "@zebrabi/charts/dist/lib/consts";

export const varianceCategories: Categories = [
  {
    displayName: "Variance",
    charts: [
      /*
      {
        id: "variance-structure-bar",
        title: "Vertical variance chart",
        image: VarianceStructureBarImage,
        sampleData: chartSampleDataVertical,
        settings: {
          chartType: ChartType.Variance,
          showVerticalCharts: true,
          chartLayout: ABSOLUTE,
        }
      },
      {
        id: "variance-structure-dot",
        title: "Vertical variance chart",
        image: VarianceStructureDotImage,
        sampleData: chartSampleDataVertical,
        settings: {
          chartType: ChartType.Variance,
          showVerticalCharts: true,
        }
      },
      */
      {
        id: "variance-time-column",
        title: "Variance column chart",
        image: VarianceStructureColumnImage,
        sampleData: VarianceStructureColumnSampleData,
        commentData: VarianceStructureColumnCommentData,
        tooltip:
          "Compare two sets of series with each other by calculating the difference – visualize how absolute measures changes. The key ingredient of successful business reports.",
        settings: {
          chartType: ChartType.Variance,
          showVerticalCharts: false,
          chartLayout: ABSOLUTE,
        },
      },
      /*
      {
        id: "variance-time-area",
        title: "Vertical variance chart",
        image: VarianceTimeAreaImage,
        sampleData: chartSampleDataHorizontal,
        settings: {
          chartType: ChartType.Variance,
          showVerticalCharts: false,
        }
      },
      */
      {
        id: "variance-time-dot",
        title: "Variance pin chart",
        image: VarianceTimeDotImage,
        sampleData: VarianceTimeDotSampleData,
        commentData: VarianceTimeDotCommentData,
        tooltip:
          "Compare two sets of series with each other by calculating the difference – display the relative change in data series. The key ingredient of successful business reports.",
        settings: {
          chartType: ChartType.Variance,
          showVerticalCharts: false,
          chartLayout: RELATIVE,
        },
      },
    ],
  },
  {
    displayName: "Intergrated variance",
    charts: [
      {
        id: "integrated-variance-time-column-responsive",
        title: "Responsive variance column chart",
        image: IntegratedVarianceTimeColumnResponsiveImage,
        sampleData: IntegratedVarianceTimeColumnResponsiveSampleData,
        commentData: IntegratedVarianceTimeColumnResponsiveCommentData,
        tooltip: "Combine sets of two, three, or more charts that share the same scale and fit together thematically.",
        settings: {
          chartType: ChartType.Variance,
          showVerticalCharts: false,
          chartLayout: RESPONSIVE,
        },
      },
      {
        id: "integrated-variance-time-column",
        title: "Integrated variance column chart",
        image: IntegratedVarianceTimeColumnImage,
        sampleData: IntegratedVarianceTimeColumnSampleData,
        commentData: IntegratedVarianceTimeColumnCommentData,
        tooltip:
          "Display actual values and deviations on the same chart, evaluate the importance of each deviation and its relative share of actual value, and visualize absolute and relative deviations.",
        settings: {
          chartType: ChartType.Variance,
          showVerticalCharts: false,
          chartLayout: INTEGRATED,
        },
      },
      {
        id: "integrated-variance-time-area",
        title: "Integrated variance area chart",
        image: IntegratedVarianceTimeAreaImage,
        sampleData: IntegratedVarianceTimeAreaSampleData,
        commentData: IntegratedVarianceTimeAreaCommentData,
        tooltip:
          "Display actual values and deviations on the same chart, evaluate the importance of each deviation and its relative share of actual value, analyze trends and relationships in deviation with a summation.",
        settings: {
          chartType: ChartType.Area,
          showVerticalCharts: false,
        },
      },
      {
        id: "integrated-variance-structure-bar",
        title: "Integrated variance bar chart",
        image: IntegratedVarianceStructureBarImage,
        sampleData: IntegratedVarianceStructureBarSampleData,
        commentData: IntegratedVarianceStructureBarCommentData,
        tooltip:
          "Display actual values and deviations on the same chart, evaluate the importance of each deviation and its relative share of actual value, display integrated variance of structure data series.",
        settings: {
          chartType: ChartType.Variance,
          showVerticalCharts: true,
          chartLayout: INTEGRATED,
        },
      },
    ],
  },
  /*
  {
    displayName: "Small multiples",
    charts: [
      {
        id: "variance-horizontal-small-multiples",
        title: "Horizontal variance chart",
        image: VarianceHorizontalSmallMultiplesImage,
      },
      {
        id: "variance-integrated-small-multiples",
        title: "Integrated variance chart",
        image: VarianceIntegratedSmallMultiplesImage,
      },
    ],
  },
  */
];
