import { createFromTemplate } from "@zebrabi/global-toolbar-old/helpers/template";
import { persistManagerInstance } from "@zebrabi/office-settings/PersistManager";
import { selectNoLabel } from "@zebrabi/global-toolbar-old/components/form/select";
import { InputOption } from "@zebrabi/global-toolbar-old/components/global-toolbar/InputConfig";

export const DATA_SOURCE = "SourceData";
export const EMPTY_WORKSHEET_MSG = "Loading data… Your chart will be displayed in a few seconds.";
export const PARSING_ERROR_MSG = "Unfortunately, we were not able to parse your spreadsheet data. Please select a single cell within a data range, Excel table, or pivot table that you'd like to use to create your visual.";
export const SHOW_CHOOSER_LINK_ID = "show-chooser-link-id";

export interface DataSource {
    pivotTable?: string;
    table?: string;
    range?: string;
    tableId?: string;
    worksheetId: string;
}

interface TableInfo {
    id: string;
    name: string;
    worksheetId: string;
    worksheetName?: string;
}

interface ExcelTablesCollections {
    activeWorksheetId: string;
    activeWorksheetName: string;
    excelTables: TableInfo[];
    pivotTables: TableInfo[];
}

const dialog = (inputConfig: any) => createFromTemplate(
    `<dialog id="edit-data-dialog-id" class="edit-data-dialog">
    <form method = "dialog" >
        <label>Edit data source</label>
        <div id="source-range-div" class="source-type">
            <input type="radio" id="sourceTypeRange" name="sourceType" value="range" />
            <label for="sourceTypeRange">Range&nbsp</label>
            <input type="text" id="range-input-id" required autocomplete="off" minlength="5" maxlength="15" 
            placeholder="range address: A1:C12"
            pattern="[A-Za-z]{1,4}[1-9][0-9]*:[A-Za-z]{1,4}[1-9][0-9]*">
        </div>
        <div id="source-excel-table-div" class="source-type" >
            <input type="radio" id="sourceTypeExcelTable" name="sourceType" value="table" />
            <label for="sourceTypeExcelTable">Excel Table</label>
        </div>
        <div id="source-pivot-table-div" class="source-type">
            <input type="radio" id="sourceTypePivotTable" name="sourceType" value="pivotTable" />
            <label for="sourceTypePivotTable">Pivot table</label>
        </div>
        <div>
            <button id="confirmBtn" value ="default" >OK</button>
        </div>
    </form>
    </dialog>`);

//<button value="cancel">Cancel</button>

export function createDialog(dataSource: DataSource, editDataHandler: () => void) {
    if (!dataSource) {
        return;
    }

    let addEventHandlers = false;
    let editDataDialog = <HTMLDialogElement>document.getElementById("edit-data-dialog-id");
    if (!editDataDialog) {
        document.getElementsByTagName("body")[0].append(dialog(null));
        editDataDialog = <HTMLDialogElement>document.getElementById("edit-data-dialog-id");
        addEventHandlers = true;
    }

    getExcelTables().then((result) => {

        const pivotTableRadio = <HTMLInputElement>editDataDialog.querySelector("#sourceTypePivotTable");
        const excelTableRadio = <HTMLInputElement>editDataDialog.querySelector("#sourceTypeExcelTable");
        const rangeRadio = <HTMLInputElement>editDataDialog.querySelector("#sourceTypeRange");
        const rangeInput = <HTMLInputElement>editDataDialog.querySelector("#range-input-id");
        const confirmBtn = <HTMLButtonElement>editDataDialog.querySelector("#confirmBtn");
        let tablesDropDown = null;
        let pivotTablesDropDown = null;

        if (dataSource.range) {
            rangeRadio.checked = true;
            rangeInput.required = true;
        }
        else if (dataSource.table) {
            excelTableRadio.checked = true;
            rangeInput.required = false;
        }
        else if (dataSource.pivotTable) {
            pivotTableRadio.checked = true;
            rangeInput.required = false;
        }

        const worksheet = result.activeWorksheetName;
        const worksheetId = result.activeWorksheetId;
        const excelTables = result.excelTables;
        const pivotTables = result.pivotTables;
        pivotTableRadio.disabled = pivotTables.length === 0;
        excelTableRadio.disabled = excelTables.length === 0;

        if (excelTables.length > 0) {
            editDataDialog.querySelector("#source-excel-table-div").append(selectNoLabel({
                id: "excel-tables-select",
                label: "",
                name: "table",
                options: excelTables.map(t => <InputOption>{ title: `${t.name}${!t.worksheetName || t.worksheetName === worksheet ? "" : ` (${t.worksheetName})`}`, value: t.id, default: t.name === dataSource.table }),
                type: "string",
                value: dataSource.table ? dataSource.tableId : null,
            }));
            tablesDropDown = <HTMLSelectElement>editDataDialog.querySelector("#excel-tables-select");
        }

        if (pivotTables.length > 0) {
            editDataDialog.querySelector("#source-pivot-table-div").append(selectNoLabel({
                id: "pivot-tables-select",
                label: "Pivot tables",
                name: "pivotTable",
                options: pivotTables.map(t => <InputOption>{ title: `${t.name}${!t.worksheetName || t.worksheetName === worksheet ? "" : ` (${t.worksheetName})`}`, value: t.name, default: t.name === dataSource.pivotTable }),
                type: "string",
                value: dataSource.pivotTable ? dataSource.pivotTable : null,
            }));
            pivotTablesDropDown = <HTMLSelectElement>editDataDialog.querySelector("#pivot-tables-select");
        }

        if (dataSource.range) {
            const rangeParts = (<string>dataSource.range).split("!");
            if (rangeParts.length > 1) {
                //const worksheet = rangeParts[0];
                const rangeAddress = rangeParts[1];
                rangeInput.value = rangeAddress;
            }
        }

        if (addEventHandlers) {
            rangeRadio.addEventListener("change", e => {
                const checked = (<HTMLInputElement>e.target).checked;
                rangeInput.required = checked;
            });
            pivotTableRadio.addEventListener("change", e => {
                const checked = (<HTMLInputElement>e.target).checked;
                rangeInput.required = !checked;
                // clear range validation;
                rangeInput.setCustomValidity("");
                rangeInput.reportValidity();
            });
            excelTableRadio.addEventListener("change", e => {
                const checked = (<HTMLInputElement>e.target).checked;
                rangeInput.required = !checked;
                // clear range validation;
                rangeInput.setCustomValidity("");
                rangeInput.reportValidity();
            });

            rangeInput.addEventListener("input", e => {
                rangeInput.setCustomValidity("");
                if (rangeRadio.checked) {
                    if (!rangeInput.validity.valid || !checkRangeRowsNumbers(rangeInput.value)) {
                        rangeInput.setCustomValidity("Please use the correct range address format like A1:D12")
                    }
                }
                rangeInput.reportValidity();
            });

            editDataDialog.addEventListener("click", (e) => {
                if ((<HTMLElement>e.target).tagName === "DIALOG") {
                    editDataDialog.close();
                    e.stopPropagation();
                }
            });

            editDataDialog.addEventListener('close', () => {
                editDataDialog.querySelector("#excel-tables-select")?.remove();
                editDataDialog.querySelector("#pivot-tables-select")?.remove();
            });
        }

        confirmBtn.addEventListener("click", () => {
            // save modified data source
            let newSource = dataSource;
            if (rangeRadio.checked) {
                // validate range addr?
                if (rangeInput.checkValidity()) {
                    newSource = { range: worksheet + "!" + rangeInput.value, worksheetId: worksheetId };
                }
            }
            else if (excelTableRadio.checked) {
                const selectEl = <HTMLSelectElement>editDataDialog.querySelector("#excel-tables-select")
                const tableId = selectEl?.value;
                const excelTable = excelTables?.find(t => t.id === tableId);

                if (excelTable) {
                    newSource = { table: excelTable.name, tableId: excelTable.id, worksheetId: excelTable.worksheetId };
                }
            }
            else if (pivotTableRadio.checked) {
                const selectEl = <HTMLSelectElement>editDataDialog.querySelector("#pivot-tables-select");
                const pivotTableName = selectEl?.value;
                const pivotTable = pivotTables?.find(t => t.name === pivotTableName);
                if (pivotTable) {
                    newSource = { pivotTable: pivotTable.name, worksheetId: pivotTable.worksheetId };
                }
            }

            // maybe also update only if changed "changed"?
            Office.context.document.settings.set(DATA_SOURCE, newSource);
            persistManagerInstance.update({ // ??
                objectName: DATA_SOURCE,
                properties: newSource
            });
            editDataHandler();

        }, { once: true });

        editDataDialog.showModal();

        if (dataSource.range) {
            rangeInput.focus();
        }
        else if (dataSource.table) {
            tablesDropDown?.focus();
        }
        else if (dataSource.pivotTable) {
            pivotTablesDropDown?.focus();
        }
    });
}

function checkRangeRowsNumbers(rangeAddress: string): boolean {
    if (!rangeAddress) {
        return false;
    }
    const rangeAddressRowParts = rangeAddress.match(/\d+/g);
    return rangeAddressRowParts.length === 2 && Number(rangeAddressRowParts[0]) <= Number(rangeAddressRowParts[1]);
}

async function getExcelTables(): Promise<ExcelTablesCollections> {
    return <ExcelTablesCollections>await Excel.run(async (context) => {
        let tables: ExcelTablesCollections = {
            activeWorksheetId: null,
            activeWorksheetName: null,
            excelTables: [],
            pivotTables: []
        };

        const workbook = context.workbook;
        const activeWorksheet = workbook.worksheets.getActiveWorksheet();
        activeWorksheet.load({ id: true, name: true });

        const excelTables = workbook.tables;
        const tablesCount = excelTables.getCount();
        const pivotTables = workbook.pivotTables;
        const pivotTablesCount = pivotTables.getCount();

        await context.sync();

        tables.activeWorksheetId = activeWorksheet.id;
        tables.activeWorksheetName = activeWorksheet.name;

        if (tablesCount.value > 0) {
            const loadOptions: Excel.Interfaces.TableCollectionLoadOptions = { id: true, name: true, worksheet: { id: true, name: true } };
            excelTables.load(loadOptions);
            await context.sync();

            excelTables.items.forEach(table => {
                tables.excelTables.push({
                    id: table.id,
                    name: table.name,
                    worksheetId: table.worksheet.id,
                    worksheetName: table.worksheet.name
                });
            });
        }

        if (pivotTablesCount.value > 0) {
            const loadOptions: Excel.Interfaces.PivotTableCollectionLoadOptions = { id: true, name: true, worksheet: { id: true, name: true } };
            pivotTables.load(loadOptions);
            await context.sync();

            pivotTables.items.forEach(table => {
                tables.pivotTables.push({
                    id: table.id,
                    name: table.name,
                    worksheetId: table.worksheet.id,
                    worksheetName: table.worksheet.name,
                });
            });
        }

        return tables;
    }).catch((error) => {
        console.log("Excel.Run select range error: " + error);
        if (error instanceof OfficeExtension.Error) {
            console.log('Debug info: ' + JSON.stringify(error.debugInfo));
        }
    });
}
