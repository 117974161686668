/* global Office, OfficeRuntime */
import AlternativeLoginRequiredException from "../../exceptions/AlternativeLoginRequiredException";
import { ACTION_SIGNED_OUT } from "./constants";
import CriticalLoginException from "../../exceptions/CriticalLoginException";
import UserLoginAbortedException from "../../exceptions/UserLoginAbortedException";

export class OfficeTokenManager {
  private static _instance: OfficeTokenManager;

  public async getSilentAccessToken(): Promise<string> {
    return Office.auth.getAccessToken({
      allowSignInPrompt: false,
      allowConsentPrompt: false,
      forMSGraphAccess: false,
    });
  }

  public async getAccessToken(): Promise<string> {
    if (localStorage?.getItem(ACTION_SIGNED_OUT) === "true") {
      throw new AlternativeLoginRequiredException("User signed out, require sign in!");
    }

    const getAccessTokenOptions: Office.AuthOptions = {
      allowSignInPrompt: true,
      allowConsentPrompt: true,
      forMSGraphAccess: false,
    };

    try {
      const isIdentityApiSupported: boolean = Office.isSetSupported("IdentityAPI", "1.3");

      if (isIdentityApiSupported) {
        return await Office.auth.getAccessToken(getAccessTokenOptions);
      }

      return await OfficeRuntime.auth.getAccessToken(getAccessTokenOptions);
    } catch (error) {
      this.handleFallbackLogin(error);
    }
  }

  /**
   * Handles the fallback exceptions
   * @param error
   * @throws AlternativeLoginRequiredException
   * @private
   */
  private handleFallbackLogin(error) {
    // based on the: https://learn.microsoft.com/en-us/office/dev/add-ins/develop/troubleshoot-sso-in-office-add-ins#causes-and-handling-of-errors-from-getaccesstoken
    switch (error.code) {
      case 13000:
        // We should fall back to the alternate authentication flow.
        throw new AlternativeLoginRequiredException("The version of the Office doesn't support SSO.");
      case 13001:
        // We can use alternate option for the authentication
        throw new AlternativeLoginRequiredException(
          "User is not signed in to Office. They need to sign in before you can get an access token."
        );
      case 13002:
        // Since add-in requires granted consent, a sign-in button should appear.
        throw new UserLoginAbortedException("User has aborted the consent prompt.");
      case 13003:
        // The code should fall back to an alternate system of user authentication.
        throw new AlternativeLoginRequiredException("Office runs an on-premise domain account.");
      case 13004:
        // The error should only be seen in development in case add-in manifest hasn't been configured correctly.
        throw new AlternativeLoginRequiredException("Invalid resource - developer error.");
      case 13005:
        // This usually means that Office has not been pre-authorized to the add-in's web service.
        // The code should fall back to an alternate system of user authentication.
        throw new AlternativeLoginRequiredException("Invalid grant");
      case 13006:
        // Only seen on Office on the web. Code should suggest that the user sign out
        // and then restart the Office browser session.
        throw new CriticalLoginException("Client error");
      case 13007:
        // You should fall back to alternate system of user authentication.
        throw new AlternativeLoginRequiredException("Unable to get an access token");
      case 13008:
        // You should aks the user to repeat the operation after previous operation has completed.
        throw new CriticalLoginException("User triggered an operation getAccessToken before previous completed.");
      case 13010:
        // If the add-in provides function that don't require the user to be signed-in, then the code should catch
        // the error and allow the add-in to stay running.
        throw new CriticalLoginException("Running in Edge browser");
      case 13012:
        // We should fall back to alternate system of user authentication;
        throw new AlternativeLoginRequiredException("Office is running in unsupported environment or mode");
      case 13013:
        // developer error
        throw new CriticalLoginException("The getAccessToken requested too many times in a short amount of time.");
      case 50001:
        // should fall back to alternate system of user authentication
        throw new AlternativeLoginRequiredException("Cached old copy of office.js or using old version of Office.");
      default:
        console.error("Unexpected error", error);
        throw new CriticalLoginException("Unexpected error");
    }
  }

  public static createInstance(): OfficeTokenManager {
    if (!OfficeTokenManager._instance) {
      OfficeTokenManager._instance = new OfficeTokenManager();
    }
    return OfficeTokenManager._instance;
  }
}

export const officeTokenManager = OfficeTokenManager.createInstance();
