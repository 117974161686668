
export enum SidebarChartSettings {
    invert,
    chartLayout,
    showGrandTotal,
    grandTotalLabel,
    limitOutliers,
    maxOutlierValue,
    minOutlierValue,
    referenceDisplayType,
    showAllForecastData,
    currentPeriodVarianceOptions,
    currentPeriodVarianceCondition,
    dayInMonthVarianceCondition,
    dayInWeekVarianceCondition,
    monthInQuarterVarianceCondition,
    monthInYearVarianceCondition,
    showVerticalCharts,
    handleNullsAsZeros
}
