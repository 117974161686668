import { analyticsHandler } from "./AnaliticsHandler";
import { InsertType } from "./definitions";

export async function trackUsedDataSource(insertType: InsertType, data: any[][]): Promise<void> {
    const sourceData = data;
    //console.log(structuredClone(data));

    const anyonimizedData = anyonimyzeNumericData(sourceData);

    // console.log(anyonimizedData);
    // console.log(convertToCSV(anyonimizedData));

    // Note: timeout is needed to ensure that the report is sent after the analytics instance is initialized
    setTimeout(() =>
        analyticsHandler.report("insert/data-source", {
            sourceData: {
                insertType: insertType,
                data: anyonimizedData,
                csv: convertToCSV(anyonimizedData)
            }
            //?dataView: dataView
        })
    , 1000);
}

function convertToCSV(data: any[][]): string {
    const tab = "\u0009"; //"\t";   "&#9"; // 
    const newLine = "\u000a"; //"\n";    //  "&#10";  // 
    return data.map(row => row.join(tab)).join(newLine);
}

function anyonimyzeNumericData(data: any[][]): any[][] {
    data.forEach(row => {
        row.forEach((cell, index) => {
            if (typeof cell === "number") {
                let newValue = +(cell * (Math.random() * (1.3 - 0.7) + 0.7)).toFixed(2);
                
                if (Number.isInteger(cell)) {
                    newValue = Math.round(newValue);
                }
                row[index] = newValue;
            } else {
                cell = cell?.toString().replace(/"/g, '""');
                row[index] = `"${cell}"`;
            }
        });
    });

    return data;
}

function convertToCSV2(data: any[][]): string {
    const delimiter = ",";  //"\u0009"; //"\t";   "&#9"; // 
    const newLine = "\r\n"; //"\u000a"; //"\n";    //  "&#10";  // 
    return data.map(row => row.join(delimiter)).join(newLine);
}

function anyonimyzeNumericData2(data: any[][]): any[][] {
    data.forEach(row => {
        row.forEach((cell, index) => {
            if (typeof cell === "number") {
                let newValue = +(cell * (Math.random() * (1.3 - 0.7) + 0.7)).toFixed(2);

                if (Number.isInteger(cell)) {
                    newValue = Math.round(newValue);
                }
                row[index] = newValue;
            } else {
                //cell = cell?.toString().replace(/"/g, '""');
                //row[index] = `"${cell}"`;
            }
        });
    });

    return data;
}
