import React from "react";
import ReactDOM from "react-dom/client";
import { ChartChooserScreen } from "./screen";
import { ChartChooserStore } from "../store";

const ROOT_ELEMENT_ID = "chart-chooser-react";

function addRootDOMElement() {
  document.body.querySelectorAll(`#${ROOT_ELEMENT_ID}`).forEach((element) => element.remove());
  const tableDataEditorRoot = document.createElement("div");
  tableDataEditorRoot.setAttribute("id", ROOT_ELEMENT_ID);
  document.body.prepend(tableDataEditorRoot);
}

export function mountReactChartChooser(setVisible: boolean = false) {
  if (setVisible) {
    ChartChooserStore.set({
      isVisible: true,
      selectedChart: ""
    });
  }

  addRootDOMElement();
  const reactRoot = ReactDOM.createRoot(document.getElementById(ROOT_ELEMENT_ID) as HTMLElement);
  reactRoot.render(
    <React.StrictMode>
      <ChartChooserScreen />
    </React.StrictMode>
  );
}
