
// Special characters
export const EMPTY = "";
export const LINE_BREAK = "\n";

// Column headers
export const DELTA = "Δ";
export const PERCENT = "%";
export const DELTA_PL = "ΔPL";
export const DELTA_PY = "ΔPY";
export const DELTA_AC = "ΔAC";
export const DELTA_FC = "ΔFC";
export const DELTA_PL_PERCENT = "ΔPL%";
export const DELTA_PY_PERCENT = "ΔPY%";
export const DELTA_AC_PERCENT = "ΔAC%";
export const DELTA_FC_PERCENT = "ΔFC%";
export const AC = "AC";
export const FC = "FC";
export const PL = "PL";
export const PY = "PY";

// D3 elements and classes
export const CIRCLE = "circle";
export const LINE = "line";
export const POLYLINE = "polyline";
export const POINTS = "points";
export const BACKGROUND_COLOR = "background-color";
export const DIV = "div";
export const ASIDE = "aside";
export const P = "p";
export const SPAN = "span";
export const A = "a";
export const BODY = "body";
export const PATH = "path";

// Colors
export const YELLOW = "#F2C811";
export const GRIDLINE_COLOR = "#b0b0b0";
export const BLACK = "#000";
export const WHITE = "#fff";
export const TRANSPARENT = "#ffffff00";
export const GRAY = "#808080";
export const LIGHTGRAY = "#D3D3D3";
export const ORANGE = "#ffa500";
export const HIGHLIGHT_COLOR = "#0070C0";

export const CUSTOMER_STYLE_VISIBLE = "COMPANY_STYLE_VISIBLE";
export const CUSTOMER_NEUTRAL_COLOR = "COMPANY_NEUTRAL_COLOR";
export const CUSTOMER_POSITIVE_COLOR = "COMPANY_POSITIVE_COLOR";
export const CUSTOMER_NEGATIVE_COLOR = "COMPANY_NEGATIVE_COLOR";
export const CUSTOMER_LINE_COLOR = "COMPANY_LINE_COLOR";
export const CUSTOMER_MARKER_COLOR = "COMPANY_MARKER_COLOR";
export const CUSTOMER_AXIS_COLOR = "COMPANY_AXIS_COLOR";
export const CUSTOMER_GRIDLINE_COLOR = "#b0b0b0";
export const CUSTOMER_DOT_CHART_COLOR = "#4080FF";
export const CUSTOMER_MORE_COLORS = "COMPANY_MORE_COLORS";
export const CUSTOMER_PY_COLOR = "COMPANY_PY_COLOR";
export const CUSTOMER_FC_COLOR = "COMPANY_FC_COLOR";
export const CUSTOMER_PL_COLOR = "COMPANY_PL_COLOR";
export const CUSTOMER_APPLY_PATTERNS = "COMPANY_APPLY_PATTERNS";

// Font attributes and values
export const FONT_FAMILY = "font-family";
export const FONT_SIZE = "font-size";
export const FONT_STYLE = "font-style";
export const FONT_WEIGHT = "font-weight";
export const BOLD = "bold";
export const NORMAL = "normal";
export const ITALIC = "italic";
export const TEXT_ANCHOR = "text-anchor";
export const SUBSTITUTE_FONT = "helvetica, arial, sans-serif";
export const DEFAULT_FONT = `Calibri, ${SUBSTITUTE_FONT}`;
export const DEFAULT_FONT_SIZE = 10;
export const SEGOE_UI = `'Segoe UI', wf_segoe-ui_normal, ${SUBSTITUTE_FONT}`;
export const SEGOE_UI_LIGHT = `'Segoe UI Light', wf_segoe-ui_light, ${SUBSTITUTE_FONT}`;
export const SEGOE_UI_SEMIBOLD = `'Segoe UI Semibold', wf_segoe-ui_semibold, ${SUBSTITUTE_FONT}`;
export const SEGOE_UI_BOLD = `'Segoe UI Bold', wf_segoe-ui_bold, ${SUBSTITUTE_FONT}`;

// CSS atributes and values
export const NONE = "none";
export const HIDDEN = "hidden";
export const DISPLAY = "display";
export const VISIBILITY = "visibility";
export const VISIBLE = "visible";
export const LEFT = "left";
export const RIGHT = "right";
export const CENTER = "center";
export const BLOCK = "block";
export const FLEX = "flex";
export const FLEX_DIRECTION = "flex-direction";
export const MARGIN = "margin";
export const MARGIN_TOP = "margin-top";
export const MARGIN_LEFT = "margin-left";
export const MARGIN_BOTTOM = "margin-bottom";
export const MARGIN_RIGHT = "margin-right";
export const POSITION = "position";
export const Z_INDEX = "z-index";
export const COLOR = "color";
export const PADDING = "padding";
export const ROW = "row";
export const ROW_REVERSE = "row-reverse";
export const COLUMN = "column";
export const COLUMN_REVERSE = "column-reverse";
export const SOLID = "solid";

// Other d3 attributes and values
export const STROKE = "stroke";
export const STROKE_WIDTH = "stroke-width";
export const STROKE_DASHARRAY = "stroke-dasharray";
export const X = "x";
export const Y = "y";
export const CX = "cx";
export const CY = "cy";
export const R = "r";
export const WIDTH = "width";
export const HEIGHT = "height";
export const OPACITY = "opacity";
export const POINTER_EVENTS = "pointer-events";
export const FILL = "fill";
export const FILL_OPACITY = "fill-opacity";
export const PX = "px";
export const PT = "pt";

export const FONT_SIZE_UNIT = PT;

export const X1 = "x1";
export const X2 = "x2";
export const Y1 = "y1";
export const Y2 = "y2";

// Events
export const MOUSEOVER = "mouseover";
export const MOUSEENTER = "mouseenter";
export const MOUSELEAVE = "mouseleave";
export const CLICK = "click";
export const DRAG = "drag";
export const DRAGSTART = "start";
export const DRAGEND = "end";
export const CONTEXT_MENU = "contextmenu";

// Headers
export const VALUE_HEADER = "valueHeader";
export const SECOND_VALUE_HEADER = "secondValueHeader";
export const REFERENCE_HEADER = "referenceHeader";
export const SECOND_REFERENCE_HEADER = "secondReferenceHeader";
export const ABSOLUTE_DIFFERENCE_HEADER = "absoluteDifferenceHeader";
export const SECOND_ABSOLUTE_DIFFERENCE_HEADER = "secondAbsoluteDifferenceHeader";
export const RELATIVE_DIFFERENCE_HEADER = "relativeDifferenceHeader";
export const SECOND_RELATIVE_DIFFERENCE_HEADER = "secondRelativeDifferenceHeader";

// Element classes
export const HEADER_DROPDOWN_SETTINGS_ARROW = "drop-down-settings-arrow";
export const HEADER_TOOLTIP = "header-tooltip";

// Custom attributes
export const D = "d";

// Info Box and comment box
export const ZEBRABI_CHART_SVG_CONTAINER = "zebrabi-chart-svg-container";

export const INFO_BOX_CONTAINER = "zebrabi-info-container";
export const COMMENT_BOX_RESIZE_LINE = "comment-box-resize-line";
export const COMMENT_BOX_SHADOW_DEFAULT = false;
export const COMMENT_BOX_MARGIN_DEFAULT = 10;
export const COMMENT_BOX_PADDING_DEFAULT = 10;
export const FREE_MODE_COMMENT_LIMIT = 3;
